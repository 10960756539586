﻿// scss-compile-options: :style => :compressed

.fmTree {	
	background-color:white;
}

.fmView, .fmTree {
	background-color:white;
	a.thumb {		
		width:140px;
		/*height:130px;*/
		display:block;
		text-align:center;
		img {
			max-width:140px;
			max-height:105px;
			line-height:1em;
		}

		&.rep {
			background:url('../../Content/assets/fm/folder_90.png') no-repeat center top;
		}

		&.file {
			background:url('../../Content/assets/fm/image_90.png') no-repeat  center top;	
		}

		&.home {
			background:url('../../Content/assets/fm/folderRoot_90.png') no-repeat  center top;	
		}

		&.parent {
			background	:url('../../Content/assets/fm/folderParent_90.png') no-repeat  center top;	
		}
		&.noBackground {
			background:none;
		}
	}

	a.details {
		padding-left:22px;

		&.rep {
			background:url('../../Content/assets/fm/folder_16.png') no-repeat left center;
		}

		&.file {
			background:url('../../Content/assets/fm/image_16.png') no-repeat  left center;	
		}

		&.home {
			background:url('../../Content/assets/fm/folderRoot_16.png') no-repeat  left center;	
		}

		&.parent {
			background:url('../../Content/assets/fm/folderParent_16.png') no-repeat  left center;	
		}
	}
}
