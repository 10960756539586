﻿.gantt, .gantt2 {
	/**/
}

$ganttBorder: #DDD;

.gantt:after {
	content: ".";
	visibility: hidden;
	display: block;
	height: 0;
	clear: both;
}

.fn-gantt {
	width: 100%;
	border: 1px solid $ganttBorder;
	
	.fn-content {
		overflow: hidden;
		position: relative;
		width: 100%; 
	}

	.leftPanel {
		float: left;
		width: 0%;
		overflow: hidden;
		border-right: 1px solid $ganttBorder;
		position: relative;
		z-index: 20000; 
	}

	.row {
		float: left;
		height: 24px;
		line-height: 24px;
		margin: 0;
		position: relative; 
	}

	.leftPanel {
		.labelGantt {
			margin: 0 0 0 5px; 
		}
		.row0 {
			border-top: 1px solid $ganttBorder;
		}
		.name, .desc {
			float: left;
			height: 23px;
			margin: 0;
			border-bottom: 1px solid $ganttBorder;
		}
		.name, .desc {
			width: 100px;
		} 
	}
	.spacer {
		margin: -2px 0 1px 0;
		border-bottom: none;
	}
	.rightPanel {
		float: left;
		width: 100%;
	/*overflow: hidden;*/
		overflow-x: scroll;
		overflow-y: hidden;
	}
	.dataPanel {
		margin-left: 0px;
		border-right: 1px solid $ganttBorder;
		&:after {
			clear: both; 
			content: ".";
			display: block;
			height: 0;
			visibility: hidden;
		} 
	}
	.day {
		overflow: visible;
		width: 24px;
		line-height: 24px;
		text-align: center;
		border-left: 1px solid $ganttBorder;
		border-bottom: 1px solid $ganttBorder;
		margin: -1px 0 0 -1px;
	}
	.hollyday {
		background-color: #f9e5e2;
		height: 23px;
		margin: 0 0 -1px -1px;
	}
	.today {
		background-color: #e2ebff;
		height: 23px;
		margin: 0 0 -1px -1px; 
	}
	.sa, .sn, .wd {
		height: 23px;
		margin: 0 0 0 -1px;
	}
	.sa, .sn {
		background-color: #eeeeee; 
	}
	.wd {
		background-color: #f6f6f6;
	}
	.rightPanel {
		.month, .year, .week {
			float: left;
			overflow: hidden;
			border-left: 1px solid $ganttBorder;
			border-bottom: 1px solid $ganttBorder;
			height: 23px;
			margin: 0 0 0 -1px;
			background-color: #f6f6f6;
			text-align: center;
		} 
	} 
}

/* left panel */

/* right panel */

.fn-gantt-hint {
	border: 5px solid #edc332;
	background-color: #fff5d4;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	padding: 10px;
	position: absolute;
	display: none;
	z-index: 10001; 
}

.fn-gantt {
	.bar {
		border: none;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		background-color: #89c5f8;
		height: 18px;
		margin: 4px 3px 3px 3px;
		position: absolute;
		z-index: 10000;
		.labelGantt {
			line-height: 18px;
			font-weight: bold;
			min-width: 100px;
			text-align: center;
			overflow: hidden; 
		} 
	}


	.ganttRed {
		background-color: #8F0000; 
	}
	.ganttGreen {
		background-color: #b3cf6f;
	}
	.ganttOrange {
		background-color: #ff7e24; 
	}

	.bottom {
		clear: both;
		background-color: #f6f6f6;
		width: 100%; 
	}
	.navigate {
		text-align: center;
		border-top: 1px solid $ganttBorder;
		.nav-slider {
			width: 390px;
			display: inline-block; 
		}
		.nav-slider-left, .nav-slider-right {
			text-align: center;
			height: 20px;
			display: inline-block; 
		}
		.nav-slider-left {
			float: left; 
		}
		.nav-slider-right {
			float: right; 
		}
		.nav-slider-content {
			text-align: left;
			width: 160px;
			height: 20px;
			display: inline-block;
			padding: 10px;
			margin: 0; 
		}
		.nav-slider-bar, .nav-slider-button {
			position: absolute;
			display: inline-block; 
		}
		.nav-slider-bar {
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			width: 150px;
			height: 10px;
			background-color: $ganttBorder;
			margin: 5px 0 5px 0; 
		}
		.nav-slider-button {
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			width: 14px;
			height: 14px;
			background-color: #888;
			left: 0px;
			top: 0px;
			margin: -2px 0 0 0;
			cursor: pointer; 
		}
		.page-number {
			display: inline-block;
			font-size: 10px;
			height: 20px;
			span {
				color: #666666;
				margin: 5px;
				position: relative;
				top: 13px; 
			} 
		}
		a {
			&:link, &:visited, &:active {
				text-decoration: none; 
			}
		} 
	}
	.nav-link {
		margin: 0px 0px 8px 0px;
		display: inline-block;
		width: 20px;
		height: 20px;
		font-size: 0px; 
	}
	.navigate {
		.nav-page-back {
			background-position: 0 0; 
		}
		.nav-page-next {
			background-position: -50px 0;
			margin-right: 15px; 
		}
		.nav-slider .nav-page-next {
			margin-right: 5px; 
		}
		.nav-page-back:hover {
			background-position: 0 -20px;
		}
		.nav-page-next:hover {
			background-position: -50px -20px;
		}
		.nav-begin {
			background-position: -70px 0;
		}
		.nav-prev-week {
			background-position: -90px 0; 
		}
		.nav-prev-day {
			background-position: -110px 0;
		}
		.nav-next-day {
			background-position: -130px 0;
		}
		.nav-next-week {
			background-position: -150px 0; 
		}
		.nav-end {
			background-position: -170px 0; 
		}
		.nav-zoomOut {
			background-position: -190px 0;
			&:hover {
				background-position: -190px -20px;
			} 
		}
		.nav-zoomIn {
			background-position: -210px 0;
			margin-left: 15px;
			&:hover {
				background-position: -210px -20px; 
			} 
		}
		.nav-now {
			background-position: -230px 0;
		}
		.nav-slider .nav-now {
			margin-right: 5px; 
		}
		.nav-now:hover {
			background-position: -230px -20px;
		}
		.nav-begin:hover {
			background-position: -70px -20px;
		}
		.nav-prev-week:hover {
			background-position: -90px -20px;
		}
		.nav-prev-day:hover {
			background-position: -110px -20px; 
		}
		.nav-next-day:hover {
			background-position: -130px -20px;
		}
		.nav-next-week:hover {
			background-position: -150px -20px;
		}
		.nav-end:hover {
			background-position: -170px -20px;
		}
	}
}

/* bottom (navigation) */

.fn-gantt-loader {
	z-index: 30000;
}

.fn-gantt-loader-spinner {
	width: 100px;
	height: 20px;
	position: absolute;
	margin-left: 50%;
	margin-top: 50%;
	text-align: center;
	span {
		color: #fff;
		font-size: 12px;
		font-weight: bold;
	} 
}