﻿$green: #D5FFC6;
$orange: #F7E879;
$red: #FFC6C6;
$darkred: #9d261d;
$grey: #d5d5d5;
$softgrey: #F2F2F2;
$darkbluedetails: #0d0d6e;
$softblueimport: #0070C0;
$lightorange: #FFCC66;
$orangeintense: #e18501;
$fssgreen: #93C90E;
$ercbuttongreen: #48A23F;

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    not supported by any browser */
}

/**************************************************************************************/
/******************************** Admin intervenants **********************************/
/**************************************************************************************/
label#attribut-signature-elec {
    margin-bottom: 20px;
    vertical-align: middle;
}

.alert-siret, .alert-rc-demat {
    > div {
        display: inline-block;
        padding-left: 0;
    }

    &.alert-siret-info, &.alert-rc-demat-info {
        color: #3a87ad;
    }

    &.alert-siret-warning, &.alert-rc-demat-warning {
        color: #c09853;
    }

    div.alert-icon {
        float: left;
        padding: 8px 10px 0 0;
    }
}

.alert-rc-demat {
    margin-top: 10px;
}

/**************************************************************************************/
/*********************** la vue des notifications PGI *********************************/
/**************************************************************************************/

// Todo JIRA1235 : TRES temporaire, pour la mise en recette, mais doit être défini au niveau du style BS3 : à voir au merge
.dropdown-menu > li > a {
    white-space: normal !important;
}

div.task-worker-recette {
    margin: 20px 0 30px 0;
}

div.oged-tasks-header-actions {
    margin-top: 20px;
}

.oged-error {
    color: black;
}

span.types-statuts {
    font-size: 15px;
    margin-right: 15px;
}

span.nb-tasks {
    margin-left: 15px;
}

p {
    &.datepicker-bloc-oged {
        img.ui-datepicker-trigger {
            vertical-align: top;
        }
    }
}


.table-oged {
    tr {
        // Pour uniformité en cas de ligne sans bouton
        height: 37px;
    }

    td {
        vertical-align: middle;
    }

    &.table-oged thead {
        .sort, .sort_asc, .sort_desc {
            cursor: pointer;
        }

        .sort {
            background: url('../../Content/images/sort_both.png') no-repeat center right;
        }

        .sort_asc {
            background: url('../../Content/images/sort_asc.png') no-repeat center right;
        }

        .sort_desc {
            background: url('../../Content/images/sort_desc.png') no-repeat center right;
        }
    }
}


/**************************************************************************************/
/******************************* La vue Affaire ***************************************/
/**************************************************************************************/
.command-list-title {
    margin: 10px 0 10px 5px;
}

.table-affaire-commandes {
    thead {
        tr {
            td {
                border: none;
            }
        }
    }

    tbody {
        tr {

            &.master-table-row {
                cursor: pointer;
                height: 55px;

                td {
                    vertical-align: middle;
                    border-right: none;
                    border-left: none;
                }
            }

            .add-ligne-releve {
                margin-right: 45px;
            }

            &.signature-neutre {
                background-color: $grey;
            }

            &.signature-red {
                background-color: $red;
            }

            &.signature-green {
                background-color: $green;
            }

            &.signature-orange {
                background-color: $lightorange;
            }

            span.loupe-refus {
                cursor: pointer;

                img {
                    width: 16px;
                }
            }

            td {
                img.wait-for-check {
                    width: 32px;
                    display: none;

                    &.sign-btn-clicked {
                        padding-right: 18px;
                    }
                }

                .btn {
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                &.cell-doc-icon {
                }

                &.cell-num-commande {
                    > div {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &.cell-cotraitance {
                    img {
                        width: 25px;
                        height: auto;
                    }
                }

                &.cell-dates-travaux {
                    text-align: center;
                }

                &.cell-status-icon {
                    margin-bottom: 20px;
                    text-align: right;

                    img {
                        width: 25px;
                        height: auto;
                    }
                }

                &.cell-icon-manuel {
                    text-align: right;

                    img {
                        width: 25px;
                        height: auto;
                    }
                }

                &.cell-button-manuel {
                    text-align: left;

                    div.cell-status {
                        text-align: left;
                        float: left;
                    }

                    div.status-label-manual {
                        text-align: left;
                    }

                    div.action-button {
                        text-align: left;
                    }
                }

                &.cell-button-demat {
                    text-align: right;

                    div.cell-status {
                        text-align: left;
                        float: left;

                        span.status-signat-alert {
                            font-weight: bold;
                            color: $darkred;
                            font-size: 15px;
                        }
                    }

                    div.action-btn-demat {
                        text-align: right;
                    }

                    div.action-btn-manuel {
                        text-align: left;
                    }
                }

                &.cell-delete {
                    text-align: right;
                }

                &.cell-arrow {
                    padding-right: 20px;
                    text-align: right;

                    span.dropdown-arrow-commande {
                        text-align: right;
                        color: grey;

                        img {
                            width: 22px;
                        }
                    }
                }
            }
        }
    }

    .cell-montant {
        text-align: right;

        span.montant-commande {
            font-weight: bold;
            color: grey;
            font-size: 20px;
            white-space: nowrap;

            span.montant-centimes {
                font-size: 12px;
            }
        }

        .montant-receptionne-commande {
            font-weight: bold;
            color: grey;
            font-size: 15px;
            white-space: nowrap;

            .montant-centimes {
                font-size: 10px;
            }
        }
    }

    .commande-bloc-details {
        display: none;

        td {
            padding-left: 1em;
            padding-top: 0.75em;
        }
    }
}

span.etape-buttons {
    margin-bottom: 10px;

    span.ajout-commande {
        vertical-align: middle;
        margin: 10px;

        span {
            vertical-align: middle;

            img {
                display: inline-block;
                vertical-align: middle;
                padding: 0 2px 4px 0;
                top: 0 !important;
            }
        }
    }

    span.etravaux-link {
        margin: 18px;

        a > img {
            vertical-align: -0.25em;
        }
    }
}

@media (min-width:1366px) {
    td.cell-num-commande > div {
        max-width : 165px;
    }
}

@media (min-width:1600px) {
    td.cell-num-commande > div {
        max-width: 340px;
    }
}

@media (min-width:1920px) {
    td.cell-num-commande > div {
        max-width: 100%;
    }
}

/**************************************************************************************/
/************* Pour l'ajout des lignes de relevés/FSS par les internes ****************/
/**************************************************************************************/
div.ajout-releve {
    padding: 2px 0 10px 6px;

    a {
        vertical-align: middle;

        img {
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px 4px 0;
        }
    }
}

.table-commande-releves {

    thead {
        tr {
            td {
                font-weight: bold !important;
                color: $darkbluedetails;
                border-bottom: 1px solid #ddd;

                &.last-releve-header {
                    border-bottom: none;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                border-right: 1px solid #ddd;
                border-left: 1px solid #ddd;
                overflow: auto;
                width: 33.3%;

                div.releve-file {
                    padding: 4px 0 4px 5px;
                }
            }

            &.file-releves-row {
                height: 58px;

                td {
                    border-top: none;
                    border-bottom: 1px solid #ddd;
                    vertical-align: bottom;

                    &.cell-releves-delete {
                        border: none;
                        padding-left: 25px;
                        width: 20px !important;
                    }

                    a.delete-file-ligne {
                        img {
                            vertical-align: bottom;
                        }
                    }

                    a.add-file-to-ligne {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}


/**************************************************************************************/
/******************** Pour l'historique des commandes *********************************/
/**************************************************************************************/
span.dropdown-commande {
    color: $softblueimport;
    cursor: pointer;
}

div.commande-menu-title {
    padding: 2px 0 10px 6px;
    font-weight: bold;
    color: $softblueimport;
    cursor: pointer;
}

.table-commande {
    display: none;
}

.table-commande-histo {
    td {
        border: 1px solid #ddd;
    }

    thead {
        tr {
            td {
                font-weight: bold !important;
                color: $softblueimport;
            }
        }
    }

    tbody {
        tr {
            td {
                vertical-align: middle;
            }
        }
    }
}

/**************************************************************************************/
/******************************* Pour les prérelevé contradictoire ********************/
/**************************************************************************************/
.pre-releve-table {
    border: 1px solid #ddd;
    padding: 10px;
    margin-left: 25px;
    margin-right: 5px;
    margin-bottom: 10px;

    .releve-file {
        margin-bottom: 5px;
    }
}

/**************************************************************************************/
/******************************* Pour les RC et les FSS *******************************/
/**************************************************************************************/
.table-commande.rc-fss-table {

    .rc-fss-row-header, .rc-fss-row {
        display: flex;
        justify-content: space-between;
        margin-left: 25px;
        margin-bottom: 10px;

        > div {
            flex: 1;
            margin: 0 5px;
        }
    }

    .rc-fss-row {
        height: 85px;
    }

    .rc-fss-row-header {
        font-weight: bold;

        .rc-header {
            color: $softblueimport;
        }

        .fss-header {
            display: flex;
            color: $fssgreen;

            .fss-header-title {
                flex-grow: 1;
            }

            .rc-fss-refresh-header {
                flex-shrink: 1;
                cursor: pointer;
                height: 16px;
                line-height: 16px;
            }
        }
    }
}

.rc-fss-commande {

    .rc-fss-informations {
        margin: 0 5px 5px 30px;
        padding: 5px;
        background: $orange;
    }
}

.RC-FSS-cell {
    display: flex;
    align-items: stretch;
    background-color: $softgrey;

    > div {
        flex: 1;
        display: flex;
        align-items: stretch;
    }

    .RC-FSS-head {
        align-items: center;
        flex-direction: column;
        justify-content: space-evenly;
        max-width: 85px;
        width: 85px;
        height: 85px;
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 12px;

        &.RC-head {
            background-color: $softblueimport;

            img {
                display: block;
                width: 35px;
                height: 35px;
            }

            .rc-folder {
                color: $softblueimport;
            }
        }

        &.FSS-head {
            background-color: $fssgreen;
            color: $fssgreen;

            .fa-eur::before {
                margin-left: -2px;
            }

            .FSS-status {
                color: white;
            }
        }
    }

    .bold {
        font-weight: bold;
    }

    .RC-FSS-body {

        .file-link {
            height: 16px;
        }

        .RC-FSS-end {
            flex-grow: 1;
            align-items: flex-end;
            justify-content: space-between;
            padding: 10px 0;

            &.FSS-attached, &.RC-attached {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                a {
                    justify-content: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-width: 110px;
                }

                .delete-file, .rc-fss-file-upload {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }

            .FSS-number {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 133px;
            }
        }
    }

    .RC-body {
        padding-right: 10px;

        > div {
            padding-left: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        .file-link {
            margin: 0 10px;
        }

        .eRC-button {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            background-color: $grey;
            width: 25px;
            height: 25px;
            border-radius: 6px;
            padding: 0;
            color: #666666;
            cursor: pointer;

            &:before {
                padding-left: 5px;
            }
        }
    }

    .FSS-body {
        padding-right: 10px;

        &::before {
            content: " ";
            width: 0;
            height: 0;
            border-top: 42.5px solid transparent;
            border-bottom: 42.5px solid transparent;
            border-left: 42.5px solid $fssgreen;
        }

        > div {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.rc-fss-file-upload {
    color: $softblueimport;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
}

.generic-rc-fss-buttons {
    float: right;
    padding-right: 10px;

    .rc-fss-file-upload {
        margin-bottom: 10px;
    }

    .rc-create-button {
        cursor: pointer;
        background-color: $ercbuttongreen;
        color: white;
        margin-bottom: 10px;
        padding: 5px 28px 5px 10px;
        display: flex;
        align-items: center;

        span {
            margin-right: 15px;
        }
    }
}

#eRc-frame {
    width: 100%;
    height : 400px;
    border-width: 0;
}

.closeErcButton {
    background-color: red;
    padding: 2px 3px 2px 4px;
    border-radius: 3px;
    float: right;
    margin-bottom: 5px;
    color: white;
}

/**************************************************************************************/
/************** Pour la modale de dépot de RC et FSS manuel ***************************/
/**************************************************************************************/
#attach-rc-fss-modal {
    label {
        float: none;
        margin-left: 5px;
    }

    .input-stacked {
        label {
            display: block;
            width: auto;
            font-weight: bold;
            text-align: left;
            line-height: 20px;
            margin-left: 0;
            padding-top: 0;
        }

        div.input {
            margin-left: 0;
        }
    }
}

/**************************************************************************************/
/******************** Pour la signature en elle-même **********************************/
/**************************************************************************************/
.alerte-habilitation, #alerte-motif-refus {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

#refus-motif-modal {
    textarea {
        height: 150px;
        width: 95%;
        margin: 15px 0 15px 0;
    }

    #refus-introuvable {
        margin: 15px 0 15px 0;
        font-weight: bold;
        font-size: 15px;
    }
}

.display-sign-commande {   
    width: 875px;
    padding-left: 22%;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
    .content-commande {
        text-align: left;
        padding: 20px;
        height: 100px;

        .bloc-signature {
            border: 1px solid goldenrod;
            border-radius: 5px;
            padding: 8px;
            margin-bottom: 10px;
            width: 98%;

            span.header-infos-commande {
                font-weight: bold;
            }

            table {
                margin-top: 10px;
                table-layout: fixed;

                td {
                    vertical-align: middle;

                    .fa {
                        padding-right: 8px;
                        float: left;
                    }
                }
            }

            a.btn-action-commande.real-action {
                float: right;
                margin-right: 10px;
            }

            .commande-acknowledge {
                vertical-align: bottom;
            }

            &.document {
                height: 1750px;

                .frame-document {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}


/**************************************************************************************/
/*********************** Suivi déploiement signature **********************************/
/**************************************************************************************/
div.suivi-sign-period {
    .date-label {
        margin-left: 40px;
    }

    .btn-filtrer {
        margin-left: 40px;
    }
}

.table-list-commandes-demat {
    th, td {
        text-align: center;
    }
}

.actions{
    padding-left: 20px;
}
