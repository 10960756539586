﻿//********************************/
//*  Tableau de bord - logo
//********************************/

$logoWidth: 100px;

img.logo-entreprise-intervenant {
    max-height: 18px;
    max-width: 64px;
    margin: 0 2px 0 1px;
    display: inline-block;
    vertical-align: middle;
}

.mon-logo-container {
    padding: 10px 0;

    .d-table {
        display: table;
        width: 100%;
        max-width: 600px;
        table-layout: fixed;

        .d-table-cell {
            display: table-cell;
            width: 33%;
            vertical-align: middle;

            img {
                width: $logoWidth;
            }
        }
    }
}

.modal-add-update-logo {
    width: 650px;

    form {
        margin: 0;
    }

    .d-table {
        display: table;
        width: 100%;
        max-width: 600px;
        table-layout: fixed;

        .d-table-cell {
            display: table-cell;
            width: 33%;
            vertical-align: middle;

            img {
                width: $logoWidth;
            }

            label.label-logo-error {
                display: none;
                color: red;
            }
        }
    }

    .modal-footer {
        text-align: center;

        .btn {
            float: none;
            margin: 0;

            + .btn {
                margin-left: 5px;
            }
        }
    }
}

img.logo-prestation-urgente {
    color: red;
    font-size: 1.6em;
    font-weight: bold;
}
img.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
