/* 
Hyperlink icons with CSS by Jonas Arnklint 
Documentation and examples:
http://github.com/arnklint/Hyperlink-icons-with-CSS
*/

a[href$='.doc'], a[href$='.docx'], a[href$='.rtf'],
a[href$='.txt'],
a[href$='.xls'],a[href$='.xlsx'],
a[href$='.rss'], a[href$='.atom'],
a[href$='.opml'],
a[href$='.phps'],
a[href$='.torrent'],
a[href$='.vcard'],
a[href$='.exe'],
a[href$='.dmg'], a[href$='.app'],
a[href$='.pps'],
a[href$='.pdf'],a.file-pdf,
a[href$='.xpi'],
a[href$='.fla'], a[href$='.swf'],
a[href$='.zip'], a[href$='.rar'], a[href$='.gzip'], a[href$='.bzip'], a[href$='.ace'] ,
a[href$='.ical'],
a[href$='.css'],
a[href$='.ttf'],
a[href$='.jpg'], a[href$='.gif'], a[href$='.png'], a[href$='.bmp'], a[href$='.jpeg'], a[href$='.svg'], a[href$='.eps'],
a[href$='.mov'], a[href$='.wmv'], a[href$='.mp4'], a[href$='.avi'], a[href$='.mpg'],
a[href$='.mp3'], a[href$='.wav'], a[href$='.ogg'], a[href$='.wma'], a[href$='.m4a'],
a[href ^="aim:"],
a[href ^="msnim:"],
a[href ^="xmpp:"],
a[href *="icq.com"],
a[href *="edit.yahoo.com/config/send_webmesg?"],
a[href ^="callto:"],
a[href ^="skype:"],
a[href ^="gg:"],
a[href ^="mailto:"] {
	padding: 5px 20px 5px 0;
	background-image: url('../Content/assets/fileType.gif');
	background-repeat: no-repeat;  
}

/* filändelser */
a[href$='.doc'], a[href$='.docx'],  a[href$='.rtf'] {
  background-position: right -186px; 
}
a[href$='.txt'] {
  background-position: right -954px; 
}
a[href$='.xls'],a[href$='.xlsx'] {
  background-position: right -1018px; 
}
a[href$='.rss'], a[href$='.atom'] { 
  background-position: right -282px; 
}
a[href$='.opml'] { 
  background-position: right -602px; 
}
a[href$='.phps'] { 
  background-position: right -666px; 
}
a[href$='.torrent'] { 
  background-position: right -890px; 
}
a[href$='.vcard'] { 
  background-position: right -986px; 
}
a[href$='.exe'] { 
  background-position: right -218px; 
}
a[href$='.dmg'], a[href$='.app'] { 
  background-position: right -154px; 
}
a[href$='.pps'] { 
  background-position: right -794px; 
}
a[href$='.pdf'],a.file-pdf { 
  background-position: right -634px; 
}
a[href$='.xpi'] { 
  background-position: right -730px; 
}
a[href$='.fla'], a[href$='.swf'] { 
  background-position: right -346px; 
}
a[href$='.zip'], a[href$='.rar'], a[href$='.gzip'], a[href$='.bzip'], a[href$='.ace'] { 
  background-position: right -26px; 
}
a[href$='.ical'] { 
  background-position: right -410px; 
}
a[href$='.css'] { 
  background-position: right -122px; 
}
a[href$='.ttf'] { 
  background-position: right -922px; 
}
a[href$='.jpg'], a[href$='.gif'], a[href$='.png'], a[href$='.bmp'], a[href$='.jpeg'], a[href$='.svg'], a[href$='.eps'] { 
  background-position: right -698px; 
}
a[href$='.mov'], a[href$='.wmv'], a[href$='.mp4'], a[href$='.avi'], a[href$='.mpg'] { 
  background-position: right -314px; 
}
a[href$='.mp3'], a[href$='.wav'], a[href$='.ogg'], a[href$='.wma'], a[href$='.m4a'] { 
  background-position: right -570px; 
}

/* messenger */
a[href ^="aim:"] {
  background-position: right 6px; 
}
a[href ^="msnim:"] {
  background-position: right -538px; 
}
a[href ^="xmpp:"] {
  background-position: right -474px; 
}
a[href *="icq.com"] { 
  background-position: right -442px; 
}
a[href *="edit.yahoo.com/config/send_webmesg?"] { 
  background-position: right -1050px; 
}
a[href ^="callto:"] {
  background-position: right -58px; 
}
a[href ^="skype:"] {
  background-position: right -858px; 
}
a[href ^="gg:"] {
  background-position: right -378px; 
}

/* email */
a[href ^="mailto:"] {
  background-position: right -506px; 
}

/* use this class to make sure that images you dont want to get an icon */
.imageLink {
	padding-right: 0px !important;
	background: none !important;
}
