// scss-compile-options: :style => :compressed

//************************************
//*               GENERAL             *
//************************************/

$bleuClair: #1423DC;
$bleuUrl: #0069d6;
$softblueimport: #0070C0;
// #a6d7f1
$bleuClair2: darken($bleuClair, 10%);

$blanc: #ffffff;
$gris: #d5d5d5;

//pilotages
$red: #9d261d;
$shinyRed: #e60000;
$green: #46a546;
$yellow: #ffc40d;
$blue: #049cdb;
$purple: #7a43b6;
$orange: #f89406;

// boutons
$btnColor: #2A5685;

// étapes
$etapeSansFichierBase: #E8E8E8;
$etapeWaitBase: #E6E6E6;
$etapeERColorBase: #effacf;
$etapeERDFColorBase: #F4F8FD;
$etapeBrchtColorBase: #f1e19e;
$etapePosteSourceColorBase: #f5deeb;
$etapeCartographieColorBase: #fcdba6;
$etapeSansFichierBaseCartographie: #efbf73;
//tables
$tableColorOk: #DFF0D8;
$tableColorError: #FAE5E3;
$tableColorWarning: #F9F8E3;

$titreEtapeTextColor: #515151;
$default-text-color: #404040;

//mixin spécifique e-plans
@mixin wait($basecolor: $etapeWaitBase) {
    cursor: wait;
    background-color: $basecolor;
}

@mixin positiveNeg() {
    &.positive {
        color: $green;
    }

    &.negative {
        color: $red;
    }
}

//mixin génériques
@mixin borderRadius($value) {
    //-webkit-border-radius: $value;
    -moz-border-radius: $value;
    -ms-border-radius: $value;
    -o-border-radius: $value;
    border-radius: $value;
}

@mixin boxShadow($color) {
    -webkit-box-shadow: 2px 2px 3px 2px $color;
    -moz-box-shadow: 2px 2px 3px 2px $color;
    -ms-box-shadow: 2px 2px 3px 2px $color;
    -o-box-shadow: 2px 2px 3px 2px $color;
    box-shadow: 2px 2px 3px 2px $color;
}

@mixin LigthBoxShadow() {
    -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.025), inset 0 -1px rgba(0, 0, 0, 0.025);
    -moz-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.025), inset 0 -1px rgba(0, 0, 0, 0.025);
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.025), inset 0 -1px rgba(0, 0, 0, 0.025);
}

// Repris de BS 2.3
@mixin box-sizing($boxmodel) {
    -webkit-box-sizing: $boxmodel;
    -moz-box-sizing: $boxmodel;
    box-sizing: $boxmodel;
}

@mixin degrade($first, $last) {
    background: $first;
    //* Old browsers */
    background: -moz-linear-gradient(top, $first 0%, $last 100%);
    //* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $first), color-stop(100%, $last));
    //* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, $first 0%, $last 100%);
    //* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, $first 0%, $last 100%);
    //* Opera11.10+ */
    background: -ms-linear-gradient(top, $first 0%, $last 100%);
    //* IE10+ */
    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$first}', endColorstr='#{$last}', GradientType=0);
    //* IE6 + */
    background: linear-gradient(to bottom, $first 0%, $last 100%);
    //* W3C */
}

@mixin degradeWithImage($first, $last, $url) {
    background: $first url($url) no-repeat 6px 50%;
    //* Old browsers */
    background: url($url) no-repeat 6px 50%, -moz-linear-gradient(top, $first 0%, $last 100%);
    //* FF3.6+ */
    background: url($url) no-repeat 6px 50%, -webkit-gradient(linear, left top, left bottom, color-stop(0%, $first), color-stop(100%, $last));
    //* Chrome,Safari4+ */
    background: url($url) no-repeat 6px 50%, -webkit-linear-gradient(top, $first 0%, $last 100%);
    //* Chrome10+,Safari5.1+ */
    background: url($url) no-repeat 6px 50%, -o-linear-gradient(top, $first 0%, $last 100%);
    //* Opera11.10+ */
    background: url($url) no-repeat 6px 50%, -ms-linear-gradient(top, $first 0%, $last 100%);
    //* IE10+ */
    background: url($url) no-repeat 6px 50%, linear-gradient(to bottom, $first 0%, $last 100%);
    //* W3C */
}

@mixin rotate($value) {
    -moz-transform: rotate($value);
    -webkit-transform: rotate($value);
    -ms-transform: rotate($value);
    transform: rotate($value);
}

@mixin ClearFloat() {
    &:after {
        clear: both;
    }

    &:after, &:before {
        content: "";
        display: table;
    }
}

@mixin table-bs-error() {
    td.error {
        background-color: darken($tableColorError, 10%) !important;

        &:hover {
            background-color: darken($tableColorError, 15%) !important;
        }

        .help-inline {
            color: #9d261d;
        }
    }
}

.border-box {
    @include box-sizing(border-box);
}

//*******************************/
//*  Modif de pattern bootstrap
//*******************************/

em {
    li {
        color: inherit;
    }
}

table th.header {
    padding: 2px 12px;
    position:relative;

    &::after {
        float: none;
        position: absolute;
        margin:0;
        right: 5px;
        top: 45%;
    }
}

.well {
    padding: 10px;

    &.small {
        padding: 5px;
        margin-bottom: 5px;
    }

    &.common-info {
        padding-left: 10px;
        margin-left: 20px;
        min-height: 70px;

        h3 {
            line-height: 1.3em;

            a {
                color: $default-text-color;
            }
        }

        h4 {
            line-height: 1.3em;

            a {
                color: $default-text-color;
            }
        }

        small {
            color: #808080;

            span.sep {
                padding: 0 7px;
            }
        }

        img.active-icon {
            float: left;
            margin: 2px 25px 0 5px;

            &.lock-visible {
                display: block;
            }

            &.lock-hidden {
                display: none;
            }
        }
    }

    &.success {
        background-color: #d1eed1;
        border-color: #bfe7bf;
    }

    &.warning {
        background-color: #fdf5d9;
        border-color: #fceec1;
    }

    &.expired {
        background-color: #FFD9D9;
    }
}

.popover {
    z-index: 99999;

    .inner {
        width: 550px;
    }

    hr {
        margin: 1px 0;
    }

    &.medium {
        .inner {
            width: 385px;
        }
    }

    &.large {
        .inner {
            width: 400px;
        }
    }

    &.x-large {
        .inner {
            width: 550px;
        }
    }

    .message {
        padding: 3px;

        &.reponse {
            background-color: lighten($gris, 10%);
        }
    }
}

table {
    th, td {
        line-height: 1.3em;
        padding: 2px 6px;
        text-align: left;
    }

    &.no-border {
        td {
            border: 0;
        }
    }

    .span1_5 {
        width: 40px;
    }
}

body {
    .clear {
        clear: both;
        @include ClearFloat;
    }

    &.impersonated {
        background: url('../../Content/assets/impersonated.png');

        th {
            background-color: white;
        }

        .dataTables_paginate ul li:not(.active) a {
            background-color: white;
        }

        .zebra-striped tbody tr:nth-child(even) td,
        .zebra-striped tbody tr:nth-child(even) th {
            background-color: white;
        }

        .synthese-echanges {
            tr td {
                background-color: white;
            }
        }
    }
}

.sortable-table {
    .placeholder td {
        background: #fceec1;
        background: linear-gradient(to bottom, #fceec1, #eedc94);
    }
}

html, body {
    &.locked {
        overflow: hidden;
        height: 100%;
    }
}

footer {
    margin-bottom: 17px;
}

label {
    width: 160px;
}

select {
    padding: 2px;
}

.label {
    &.info {
        background-color: $bleuClair;

        a {
            color: #fff;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

ul.tabs {
    li a {
        height: 35px;
    }
}

.pagination {
    a {
        line-height: 34px;
    }
}

input[type=hidden] {
    display: none;
}

fieldset legend {
    // Correction bootstrap
    padding-left: 180px;
}

.row.commonInfo.affaire-desactivee {
    background-color: #aaaaaa;
}

//*******************************/
//*  Accueil
//*******************************/
.welcome {
    @include borderRadius(10px);
    height: 300px;
    width: 600px;
    background: url('../../Content/assets/BandeauAcceuil.jpg') no-repeat scroll center top white;
    margin: 30px auto;

    h1 {
        font-size: 3em;
        line-height: 2em;
        margin: 20px;
    }

    .subtitle {
        margin-top: 70px;
        margin-left: 30px;

        .inner {
            width: 300px;
        }

        p {
            font-size: 1.8em;
            line-height: 1.5em;
        }

        .logIn {
            display: flex;
            align-items: center;

            label, input.large {
                width: auto;
            }

            label, input {
                margin-right: 5px;
            }

            .field-validation-error {
                color: red;
                text-shadow: 0px 0px black;
            }
        }
    }
}

.async-tb {
    text-align: center;
}

//*******************************/
//*  Header
//*******************************/
.topbar-inner {
    background: $bleuClair;

    &.debug {
        background: repeating-linear-gradient(45deg, $bleuClair, $bleuClair 10px, $bleuClair2 10px, $bleuClair2 20px);
    }

    .brand {
        padding: 0;
        float: left;

        &:hover {
            @include degrade($bleuClair, $bleuClair2);

            &.debug {
                @include degrade($bleuClair2, lighten($bleuClair, 20%));
            }
        }
    }
}

.topbar {
    position: relative;

    height: unset;

    a, p {
        color: white;
    }

    input {
        &:focus, .foccused {
            padding: 4px 9px;
            text-shadow: 0 0 0 #FFFFFF;
        }
    }

    input[type="text"] {
        background-color: rgba(255, 255, 255, 0.5);
        border: 1px solid $bleuClair2;
        color: $blanc;

        &:-moz-placeholder {
            color: $blanc;
        }

        &::-webkit-input-placeholder {
            color: $blanc;
        }

        &:placeholder {
            color: $blanc;
        }
    }

    input[type="submit"] {
        color: $bleuClair2;
        background-color: lighten($gris, 5%);

        &:hover {
            background-color: $gris;
        }
    }

    .nav {
        .dropdown-menu {
            background-clip: padding-box;
            background-color: $blanc;

            li {
                a {
                    text-shadow: none;

                    &:hover {
                        @include degrade(#eeeeee, #dddddd);
                        @include LigthBoxShadow();
                        color: $default-text-color;
                        text-decoration: none;
                    }
                }

                &.moaerdf {
                    background-color: darken($etapeERDFColorBase, 5%);
                }

                &.moaer {
                    background-color: darken($etapeERColorBase, 5%);
                }

                &.moaerdf_branchement {
                    background-color: darken($etapeBrchtColorBase, 5%);
                }

                &.moaerdf_postesource {
                    background-color: darken($etapePosteSourceColorBase, 5%);
                }


                &.moaCartographie {
                    background-color: darken($etapeCartographieColorBase, 5%);
            }
            }

            .divider {
                background-color: #EEEEEE;
                border-bottom: 1px solid $blanc;
                height: 1px;
                margin: 5px 0;
                overflow: hidden;
            }
        }
    }
}

form {
    div.conv-info-files {
        position: relative;

        span.info-size-files-max {
            position: absolute;
            left: 25px;
            width: 110px;
            top: 0;
            font-style: italic;
            line-height: 15px;
        }
    }

    .input {
        margin-left: 180px;
    }

    .date-text {
        padding-top: 8px;
        color: #808080;
    }

    #alert-no-validation {
        margin-left: 180px;
        color: red;
        font-weight: bold;

        #rappel-txt {
            text-decoration: underline;
        }
    }
}

.actions {
    padding-left: 180px;
}

.commonInfo {
    position: relative;
    padding: 5px;
    min-width: 1024px;

    .btIntervenant {
        position: absolute;
        top: 14px;
        right: 14px;
        text-align: right;
    }

    .logoCoordination {
        position: absolute;
        right: 300px;
        top: 47px;
        text-align: right;
    }

    .urlOASICE {
        position: absolute;
        top: 7px;
        right: 140px;
        text-align: right;
    }

    .urlOASICEAffInactive {
        position: absolute;
        top: 7px;
        right: 335px;
        text-align: right;
    }

    .mobile-ads {
        position: absolute;
        top: 5px;
        right: 200px;
        text-align: right;
        width: 160px;

        i.fa {
            float: left;
            margin-left: 9px;
        }

        img.eplans-mobile {
            float: left;
            margin-left: 9px;
            width: 40px;
        }

        img.play {
            float: left;
            width: 140px;
            margin-top: -10px;
        }

        span {
            font-size: 1.0em;
            float: right;
            text-align: left;
            width: 105px;
        }
    }
}

.graph.loading {
    display: block;
    background: url('../../Content/assets/ajax-loader.gif') center transparent no-repeat;
}

//*******************************/
//* Layout*/
//*******************************/
#container-container {
    .container-fluid {
        padding: 0;
        min-height: 500px;

        .sidebar {
            width: 270px;
            padding: 5px;
            padding-top: 0;
            left: 0;
        }

        .content {
            margin-left: 280px;
            margin-top: 2px;
            margin-right: 2px;
        }

        &.affaire-desactivee {
            background-color: #aaaaaa;
        }
    }
}

//Cookies
#container-cookies-popup{
    .container-cookies {
        width: 80%;
        top: auto !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 12px !important;
        margin: 0 auto !important;
        border-radius: 8px !important;
        position: fixed !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important;
        overflow: hidden !important;
        padding: 20px 10px !important;
        border-width: 1px;
        border-style: solid;
        background-color: #0D5DA0;
        border-color: #c6edf9;
    }

    .cookies-body {
        margin: 0 auto !important;
        min-width: 0 !important;
        color: white;
        font-size: larger;
    }
}

//*******************************/
//* Menu burger
//*******************************/
i {
    &.bluecolor {
        color: $bleuClair;
    }

    &.redcolor {
        color: $red;
    }
}



//*******************************/
//*  Boutons
//*******************************/
.btn {
    &.extra-small {
        padding: 3px 5px 3px;
        font-size: 10px;
    }

    &.return-to-import {
        font-weight: bold;
    }
}

.btIcon {
    /*  */
    // Pour que SASS dï¿½finisse la classe de base, meme vide
    &.btn {
        @include degrade($blanc, $gris);
        color: $btnColor;
        background-position: 2px 50%;
        background-repeat: no-repeat;
        padding-left: 26px;
    }

    &.btn:hover {
        color: $btnColor;
        text-shadow: 0 1px 0 rgba(42, 86, 133, 0.25);
    }

    &.bigBtn {
        font-weight: bold;
        height: 64px;
        line-height: 64px;
        padding-left: 70px;
    }

    @import "icones";
}

.modal-convocation {
    div {
        &.error {
            color: #9d261d;
        }
    }
    width:60%;
    left: 40%;
}

.icp-content {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;

    button.btn-urgence {
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        font-size: large;
        cursor: default;

        &.blue {
            background-color: dodgerblue;
        }

        &.purple {
            background-color: mediumpurple;
        }

        &.red {
            background-color: orangered;
        }

        &.green {
            background-color: mediumseagreen;
        }
    }

    label {
        display: inline;
    }

    .error {
        input {
            border: 1px solid #9d261d;
        }

        div {
            color: #9d261d;
        }
    }

    .bloc-formulaire {
        background-color: ghostwhite;
        border-style: groove;
        cursor: pointer;
        padding-top: 5px;
    }

    table {

        &.urgence {
            width: initial;

            td {
                font-size: small;
                border: none;
                text-align: center;
                width: 74px;
                padding-right: 0;
                padding-left: 0;
            }
        }

        caption {
            font-weight: bold;
            font-size: large;
        }

        margin-top: 5px;

        &.table-bordered {
            tr {
                td {
                    border-width: 1px;
                    border-color: #ddd;
                    border-style: solid;
                    border-collapse: separate;
                    border-left-width: 0;
                    border-bottom-width: 0;

                    input[type="checkbox"] {
                        width: 95%;
                    }
                }
            }

            border-collapse: separate; //Bug border firefox
            td:first-child {
                border-left-width: 0px;
            }

            td:last-child {
                border-right-width: 0px;
            }

            thead {
                tr {
                    td {
                        border-top-width: 0px;
                    }
                }
            }

            tr:last-child {
                td {
                    border-bottom-width: 0px;
                }
            }
        }
    }

    th.mini {
        width: 60px;
    }

    .normal {
        width: 80px;
    }

    table.bandeau-icp {
        tr {
            td {
                border-style: dotted;
            }
        }
    }

    .table-intervenant {
        margin-top: 10px;
        background-color: whitesmoke;
        border-radius: 5px;
        margin-bottom: 0px;
        page-break-inside: avoid;

        tfoot {
            input {
                width: 95%;
            }
        }
    }

    a {
        cursor: pointer;
    }

    img {
        margin-top: 3px;
    }

    .icp-footer {
        text-align: center;
        margin-top: 20px;
    }

    span.brut-yellow {
        color: $yellow;
    }
}

.bloc-plan{
    cursor:pointer;
}

.purple {
    background-color: mediumpurple;
    border-color: purple;
    background-repeat: repeat-x;
    background-image: linear-gradient(to bottom, rgb(147,112,190), mediumpurple);
    text-shadow: 0 1px 0 rgba(42, 86, 133, 0.25);
    color: white;

    &:hover {
        color: whitesmoke;
    }
}

//**********************************************/
// autocomplete jquery ui avec bootstrap https://gist.github.com/daz/2168334
//**********************************************/
.ui-autocomplete {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 12000; // Au desssus des Modales Bootstrap
    float: left;
    display: none;
    min-width: 160px;
    _width: 160px;
    padding: 4px 0;
    margin: 2px 0 0 0;
    list-style: none;
    background-color: #ffffff;
    border-color: #ccc;
    border-color: rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-width: 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;

    .ui-menu-item > a.ui-corner-all {
        display: block;
        padding: 3px 15px;
        clear: both;
        font-weight: normal;
        line-height: 18px;
        color: #555555;
        white-space: nowrap;

        &.ui-state-hover, &.ui-state-active {
            color: #ffffff;
            text-decoration: none;
            background-color: #0088cc;
            border-radius: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            background-image: none;
        }
    }
}

/**********************************************/
// Code spï¿½cifique page affaires
/**********************************************/

$etapeSansFichierBase: #E8E8E8;
$etapeWaitBase: #E6E6E6;

@mixin wait($basecolor: $etapeWaitBase) {
    cursor: wait;
    background-color: $basecolor;
}

.text-moa-erdf {
    color: darken($etapeERDFColorBase, 35%);
}

.text-moa-er {
    color: darken($etapeERColorBase, 35%);
}

.text-moa-erdf_branchement {
    color: darken($etapeBrchtColorBase, 35%);
}

.etape-container {
    margin: 0;

    li {
        display: block;
        margin: 2px;
        padding: 5px;
        @include borderRadius(5px);
        cursor: pointer;
        list-style: none;

        &.moaerdf {
            background-color: darken($etapeERDFColorBase, 5%);
            border: 1px solid darken($etapeERDFColorBase, 30%);

            &.sansFichiers {
                background-color: $etapeSansFichierBase;
            }
        }

        &.moaer {
            background-color: darken($etapeERColorBase, 5%);
            border: 1px solid darken($etapeERColorBase, 30%);

            &.sansFichiers {
                background-color: $etapeSansFichierBase;
            }
        }

        &.moaerdf_branchement {
            background-color: darken($etapeBrchtColorBase, 5%);
            border: 1px solid darken($etapeBrchtColorBase, 30%);

            &.sansFichiers {
                background-color: $etapeSansFichierBase;
            }
        }

        &.moaerdf_postesource {
            background-color: darken($etapePosteSourceColorBase, 5%);
            border: 1px solid darken($etapePosteSourceColorBase, 30%);

            &.sansFichiers {
                background-color: $etapeSansFichierBase;
            }
        }

        &.moaCartographie {
            background-color: darken($etapeCartographieColorBase, 5%);
            border: 1px solid darken($etapeCartographieColorBase, 30%);

            &.sansFichiers {
                background-color: $etapeSansFichierBaseCartographie;
            }
        }


        &:hover {
            &.moaerdf {
                background-color: darken($etapeERDFColorBase, 20%);
            }

            &.moaer {
                background-color: darken($etapeERColorBase, 20%);
            }

            &.moaerdf_branchement {
                background-color: darken($etapeBrchtColorBase, 20%);
            }

            &.moaerdf_postesource {
                background-color: darken($etapePosteSourceColorBase, 20%);
            }

            &.moaCartographie {
                background-color: darken($etapeCartographieColorBase, 20%);
        }
        }

        &.active {
            &.moaerdf {
                background-color: $etapeERDFColorBase;
            }

            &.moaer {
                background-color: $etapeERColorBase;
            }

            &.moaerdf_branchement {
                background-color: $etapeBrchtColorBase;
            }

            &.moaerdf_postesource {
                background-color: $etapePosteSourceColorBase;
            }

            &.moaCartographie {
                background-color: $etapeCartographieColorBase;
        }
        }

        &.disabled {
            cursor: not-allowed;

            &.moaerdf {
                background-color: darken($etapeERDFColorBase, 5%);
            }

            &.moaer {
                background-color: darken($etapeERColorBase, 5%);
            }

            &.moaerdf_branchement {
                background-color: darken($etapeBrchtColorBase, 5%);
            }

            &.moaerdf_postesource {
                background-color: darken($etapePosteSourceColorBase, 5%);
            }

            &.moaCartographie {
                background-color: darken($etapeCartographieColorBase, 5%);
        }
        }

        a:link, a:visited {
            color: $default-text-color;

            &:hover {
                text-decoration: none;
            }
        }

        ul li {
            border: 1px solid transparent;
            display: list-item;
            text-align: right;
            position: relative;

            &.sansFichiers {
                background-color: $etapeSansFichierBase;
                border: 1px solid darken($etapeSansFichierBase, 5%);
            }

            &.active {
                font-weight: bold;

                &.moaerdf {
                    background-color: darken($etapeERDFColorBase, 20%);
                }

                &.moaer {
                    background-color: darken($etapeERColorBase, 20%);
                }

                &.moaerdf_branchement {
                    background-color: darken($etapeBrchtColorBase, 20%);
                }

                &.moaerdf_postesource {
                    background-color: darken($etapePosteSourceColorBase, 20%);
                }

                &.moaCartographie {
                    background-color: darken($etapeCartographieColorBase, 20%);
            }
            }

            img.ico-amiante-icol {
                float: left;
                margin-top: 1px;
                margin-left: 5px;
            }
        }

        div.date {
            text-align: right;
        }
    }
}

.points-arret-container {
    th.pa {
        background-color: lighten($etapeBrchtColorBase, 10%);
    }
    th.contact {
        background-color: $etapeERDFColorBase;
    }
}

.etape-elements {
    clear: both;

    .span-2-3 {
        width: 78%;
        float: left;
        margin-right: 1%;
    }

    .span-1-3 {
        width: 21%;
        float: left;
    }
}

.moaer {
    background-color: $etapeERColorBase;
}

.moaerdf {
    background-color: $etapeERDFColorBase;
}

.moaerdf_branchement {
    background-color: lighten($etapeBrchtColorBase, 5%);
}

.moaerdf_postesource {
    background-color: lighten($etapePosteSourceColorBase, 5%);
}

.moaCartographie {
    background-color: lighten($etapeCartographieColorBase, 5%);
}

// Code spï¿½cifique Intervenants
#tableCommuneContainer {
    max-height: 500px;
    overflow-y: scroll;
}

input[data-action-type], a[data-action-type] {
    margin-right: 5px;
}

.fieldAddRemove {
    margin-left: 15px;
}

/*Code Editor*/
.CodeMirror {
    border: 1px solid #CCCCCC;
    border-radius: 3px 3px 3px 3px;
    color: #808080;
    font-size: 13px;
    padding: 4px;
    -moz-transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) inset;

    &:focus {
        border-color: rgba(82, 168, 236, 0.8);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) inset, 0 0 8px rgba(82, 168, 236, 0.6);
        outline: 0 none;
    }
}

.extra {
    height: 100px;
}

/* icone de statuts*/
a.status {
    display: block;
    width: 16px;
    height: 16px;

    &.changed {
        background: url('../../Content/assets/fugue/pencil.png') no-repeat;
    }

    &.production {
        background: url('../../Content/assets/fugue/tick.png') no-repeat;
    }
}

.dropzone {
    &.dropme {
        background: lighten($bleuClair, 45%) url('../../Content/assets/drop.png') no-repeat 50% 50% !important;

        .error {
            background-color: transparent !important;
        }
    }
}

.ui-draggable-dragging {
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;
    cursor: grab !important;
}

.ui-sortable {
    cursor: grab !important;
}

.etape-burger {
    margin: 0 5px 0;
    list-style: none;
    display: inline-block;

    a {
        &,
        &:hover {
            color: $titreEtapeTextColor;
        }
    }
    // Override bs {{
    a.menu:after,
    .dropdown-toggle:after {
        display: none;
    }
    // }
    .dropdown-menu {
        top: 27px !important;
    }
}

.form-groupe {
    @include borderRadius(5px);
    border: 1px solid $gris;
    margin-bottom: 18px;

    .actions {
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 0px;
        min-height: 29px;

        .btn {
            &[data-action-type='validation']:not([data-action-position='Left']) {
                float: right;
            }

            &[data-action-val='14']:not([data-action-position='Left']) {
                float: right;
            }

            &[data-action-type='devalidation']:not([data-action-position='Left']) {
                float: right;
            }

            &[data-action-val='6']:not([data-action-position='Left']) {
                float: right;
            }

            &.deleteTranche {
                float: right;
            }
        }
    }

    fieldset {
        margin-bottom: 0;
        padding-top: 0;
        background-color: white;

        legend {
            display: none;
        }

        .legend {
            @include borderRadius(5px 5px 0 0);
            color: $titreEtapeTextColor;
            font-size: 13px;
            font-weight: bold;
            padding: 8px;
            border-bottom: 1px solid #DDDDDD;
            line-height: 16px;

            .small {
                font-size: 0.8em;
                color: #aaa;
            }

            .textStyle {
                color: #e1ddde;
            }

            &.encours {
                background: #F7E879;
            }

            &.attente {
                background: #F5F5F5;
            }

            &.valide {
                background: #D5FFC6;
            }

            &.bloque {
                background: #FFC6C6;
            }

            &.annule {
                background: #9c949c;
            }

            &.notifie {
                background: #adedb6;
            }

             &.anotifier {
                background: #f4c67c;
            }
        }

        .clearfix {
            margin: 16px;
        }

        em.em {
            margin-left: 10px;
            font-size: 0.9em;
            color: $gris;
        }
    }
}

.quick-revue {
    margin-top: 4px;
}

.action-fichier {
    display: inline-block;
    padding-left: 20px;
    line-height: 20px;

    &.get-details {
        background: url("../../Content/assets/fugue/control-stop-270.png") no-repeat scroll 0 1px transparent;

        &.opened {
            background: url("../../Content/assets/fugue/control-stop-090.png") no-repeat scroll 0 1px transparent;
        }
    }

    &.get-zip {
        background: url("../../Content/assets/fugue/folder-zipper.png") no-repeat scroll 0 1px transparent;
    }
}

.avancement {
    @include borderRadius(5px);
    border: 1px solid threedface;
    position: relative;
    height: 18px;
    margin-bottom: 2px;

    .barre {
        height: 18px;
        background-color: $bleuClair;
    }

    .total {
        position: relative;
        top: -18px;
        color: gray;
        text-align: center;
        min-width: 40px;
    }
}

.edit-affaire {
    float: right;
    margin-right: 5px;
}

.etape-form {
    form {
        margin-left: 0;
        padding-left: 0;

        .information, .convention {
            table {
                margin-bottom: 0px;

                td {
                    &.ok {
                        background-color: $etapeERColorBase;
                    }

                    &.nok {
                        background-color: lighten($red, 40%);
                        ;
                    }

                    &.wait {
                        background-color: $etapeERDFColorBase;
                    }

                    &.middle {
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

.real-input, td {
    textarea {
        width: 98%;
        height: 100px;
        margin-right: 9px;
    }

    input[type=text].datepicker {
        width: 210px /* val Bootstrap*/ - 40px;
    }

    .ui-datepicker-trigger {
        position: relative;
        left: 7px;
        top: 7px;
        cursor: pointer;
    }
}

.input {
    .ui-datepicker-trigger {
        position: relative;
        left: 7px;
        top: 7px;
        cursor: pointer;
    }
}

.valeur-container {
    .valeur {
        p {
            margin-bottom: 0;
        }
    }

    .author {
        //display: table-cell;
        width: 10px;
    }

    .date {
        width: 10px;
        //display: table-cell;
    }
}

.bottom-well-content {
    @include borderRadius(5px);
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;

    a {
        font-size: 16px;

        &:link, &:visited {
            color: $default-text-color;
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.poste-item td, .vmiddle td {
    vertical-align: middle;
}

$histoBase: #FDF5D9;
$histoBaseBorder: #FFE084;

.histo-admin {
    @extend .bottom-well-content;
    border: 1px solid $histoBaseBorder;
    background-color: $histoBase;

    &:hover {
        border-color: darken($histoBaseBorder, 10%);
        background-color: darken($histoBase, 10%);
    }
}

$intervenantBase: #FFD1C6;
$intervenantBaseBorder: #FF9B84;

.intervenants-admin {
    @extend .bottom-well-content;
    border: 1px solid $intervenantBaseBorder;
    background-color: $intervenantBase;
    margin-top: 5px;

    &:hover {
        border-color: darken($intervenantBaseBorder, 10%);
        background-color: darken($intervenantBase, 10%);
    }
}

.modal-qualite {
    width: 720px;

    &.modal {
        .modal-body {
            padding: 0;

            form {
                padding: 0;
            }
        }
    }
}

.qualite-header {
    text-align: right;
    margin: 10px 20px 10px 0;

    .score {
        font-size: 1.5em;
        font-weight: bold;

        &.score50 {
            color: $red;
        }

        &.score90 {
            color: $yellow;
        }

        &.score100 {
            color: $green;
        }
    }
}

.modal-mesure-qualite {
    /**/
}

.modal {
    top: 45%;

    .modal-body {
        max-height: 400px;
        overflow-y: auto;
    }

    &.modal-echange-info,
    &.modal-contact {
        @extend .modal-qualite;

        .modal-body {
            padding: 15px;

            form {
                input:not([type=radio]):not([type=submit]), textarea {
                    width: 500px;
                }

                input[type=checkbox] {
                    width: auto;
                }
            }
        }
    }
    // Modales import/renommages fichier
    &.modal-import-fichier {
        left: 35%;
        width: 1000px;

        .modal-dialog {
            .modal-header {
                color: $titreEtapeTextColor;
                text-align: center;

                h3 {
                    color: $titreEtapeTextColor;
                }

                span {
                    font-size: 18px;
                }
            }

            .modal-body {

                .doublon-message {
                    color: $red;
                    font-weight: bold;
                }

                .not-authorized-message {
                    font-size: 13px;
                    color: $shinyRed;
                    font-weight: bold;
                }

                input[type~="checkbox"] {
                    vertical-align: bottom;
                }

                table thead {
                    tr {
                        &.import-etape-header {
                            border-bottom: 1px solid lightgray;
                            vertical-align: bottom;

                            th {
                                padding-top: 20px;
                            }

                            span, label {
                                font-size: 14px;
                                font-weight: bold;
                                color: gray;
                                display: block;
                                padding-top: 0;
                            }
                        }

                        &.sub-title {
                            th {
                                padding-top: 10px;
                            }
                        }

                        span.statut-etape {
                            font-size: 13px;
                            font-weight: bold;
                            font-style: italic;
                            width: 185px;

                            &.validee {
                                color: $green;
                            }

                            &.non-validee {
                                color: $shinyRed;
                            }
                        }

                        .source-access {
                            font-weight: bold;
                            margin-top: 4px;
                        }
                    }
                }

                table td {
                    vertical-align: middle;
                    border: none;
                    padding-top: 4px;
                    padding-right: 0;

                    .file-extension {
                        color: $softblueimport;
                    }

                    .file-indice, .suggested-file-ext {
                        color: dodgerblue;
                    }

                    .doublon-filename {
                        background-color: #FAE4E3;
                        color: $red;
                        font-weight: bold;
                        width: 250px;
                    }

                    .user-filename {
                        color: dodgerblue;
                        width: 250px;
                    }

                    &.champ-dest {
                        padding-left: 15px;
                        word-break: break-all;
                    }

                    &.suggested-cell {
                        border-left: 1px solid lightgray;
                        width: 280px;
                    }

                    &.file-select-cell {
                        padding-right: 10px;
                        word-break: break-all;
                    }

                    &.rename-orig-filename {
                        display: inline-block;
                        padding-left: 20px;
                        padding-top: 6px;
                        color: $softblueimport;
                        width: 320px;
                        word-break: break-all;
                    }

                    &.rename-file-cell {
                        width: 305px;
                    }
                }

                table th {
                    vertical-align: bottom;

                    &.dest-title {
                        padding-left: 15px;
                    }

                    &.statut-title {
                        padding-left: 15px;
                    }
                }

                label {
                    text-align: left;
                    padding-left: 10px;
                    width: 100%;
                    padding-top: 0;

                    &.box-select-file {
                        color: gray;
                        font-style: italic;
                    }
                }

                span {
                    &.orig-file-name {
                        color: $softblueimport;
                    }

                    &.greyed-filename {
                        color: gray;
                    }

                    &.imported-from {
                        color: cadetblue;
                        font-style: italic;
                    }
                }
            }

            .etape-non-validee {
                float: left;

                .warning-validation-title {
                    font-size: 16px;
                    font-weight: bold;
                    color: $shinyRed;
                }

                .warning-validation-text {
                    font-size: 13px;
                    font-weight: bold;
                    color: $shinyRed;
                }
            }
        }

        .noselect {
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Chrome/Safari/Opera */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                          not supported by any browser */
        }

        #import-next-step:disabled {
            background-color: gray;
        }

        #do-import:disabled {
            background-color: gray;
        }
    }
}

.modal-point-arret {
    width: 800px;

    .close {
        color: white;
        opacity: 0.5;

        &:hover {
            opacity: 0.75;
        }
    }

    .row {
        margin-left: 0;
    }

    .modal-header {
        background-color: #F80000;

        h3 {
            color: white;
        }
    }

    .modal-body {
        max-height: 500px;
        padding: 0;
    }

    .v-select {
        width: 96%;
    }

    input, textarea, select {
        width: 95%;
    }

    select.hidden, input.hidden, div.hide {
        display: none;
    }

    .pa-label {
        width: 95%;
        padding: 0.4em 0 0.4em 0.2em;
        font-weight: bold;
    }

    form {
        margin: 0;
        height: 100%;
    }

    fieldset {
        padding: 0.5em;
    }

    .dropme {
        background: #E8F4FE url('../../Content/assets/drop-pa.png') no-repeat 50% 50%;
        border: 1px solid black;

        & * {
            visibility: hidden;
        }
    }

    .form-error, .error {
        color: $shinyRed;
    }
}

.etape-file-container {
    margin-bottom: 0;
    margin-top: 10px;

    .file {
        line-height: 1.4em;
        height: 1.3em;

        .file-link {
            /**/
        }

        .file-delete {
            display: inline-table;
            background: url('../../Content/assets/delete.png') center center transparent no-repeat;
            line-height: 18px;
            width: 24px;
        }

        .file-zip {
            padding: 1px 6px 1px 20px;
            background: url('../../Content/assets/zip.png') 0 0 transparent no-repeat;
            line-height: 18px;
        }

        .file-view {
            display: inline-table;
            background: url('../../Content/assets/details.png') center center transparent no-repeat;
            line-height: 18px;
            width: 24px;
        }
    }
}

.ao-delete {
    display: inline-table;
    background: url('../../Content/assets/delete.png') center center transparent no-repeat;
    line-height: 18px;
    width: 24px;
}

.ajout-tranche-container {
    margin-bottom: 15px;
    margin-top: -12px;

    .ajout {
        float: right;
    }
}

.lambert {
    margin-right: 5px;
}

.bordered-table {
    .point-arret {

        &.encours td {
            padding: 0;
            background-color: lighten($red, 40%) !important;
            border-top: 1px solid darken($gris, 20%);

            &:hover {
                background-color: lighten($red, 45%) !important;
            }

            .action-block a {
                font-weight: bold;
                color: #000;
            }
        }

        &.solde td {
            padding: 0;
            background-color: lighten($gris, 10%) !important;
            border-top: 1px solid darken($gris, 20%);

            &:hover {
                background-color: lighten($gris, 15%) !important;
            }
        }

        div {
            .title {
                font-weight: bold;
            }
        }

        .pa-item {
            margin-left: 10px;
            border-top: 1px solid $gris;
        }

        .author {
            margin-left: 5px;
            margin-top: 5px;
        }

        .objet, .probleme, .solution, .fichiers {
            @extend .pa-item;
            margin-top: 10px;
        }

        .reponse {
            @extend .pa-item;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .action-block {
            margin-top: 10px;
            margin-left: 10px;
            margin-bottom: 10px;
        }
    }

    &.sticky {
        margin-bottom: 5px;
        border-top: none;
    }
}

table.champstable {
    margin: 0;

    tr {
        td {
            width: 50%;
            border-top: none;
            padding: 0;
            border-bottom: 1px solid #DDDDDD;

            &.even:not(:last-child) {
                border-right: 1px dotted #dddddd;
            }

            &.non-concerne {
                background-color: #E8E8E8;
            }

            .cancel-non-concerne {
                text-align: right;
            }

            hr {
                display: none;
            }

            &[class*=affaire-] {

                &:first-of-type {
                    width: 40%;
                }

                &.affaire-valide {
                    background-color: #E1EACD;
                    color: #5C6548;

                    label {
                        color: #5C6548;

                        .help-inline {
                            color: #5C6548;
                        }
                    }

                    &:first-of-type {
                        border-right-color: #b3b1b1;
                    }
                }

                &.affaire-refuse {
                    background-color: #ECB2B2;
                    color: #6C3232;

                    label {
                        color: #6C3232;

                        .help-inline {
                            color: #6C3232;
                        }
                    }
                }

                div.ValidationAffContainer {

                    &.w-50-p {
                        width: 50%;
                        display: inline-block;
                    }

                    &.v-align-top {
                        vertical-align: top;
                    }

                    &.v-align-bottom {
                        vertical-align: bottom;
                    }

                    &:not(:first-of-type) {
                        text-align: right;
                        padding-top: 10px;

                        button {
                            width: 110px;
                            margin-right: 0;

                            &:not(:first-child) {
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }

        tr:last-child {
            td {
                border-bottom: none;
            }
        }

        tr td.error {
            background-color: $tableColorError;
        }

        tr td.warning {
            background-color: $tableColorWarning;
        }

        tr td div.clearfix {
            &.error {
                margin-left: 0;
                @include borderRadius(0);
                background-color: inherit;
                padding-left: 18px;
                padding-top: 18px;
            }
        }
    }
}

div[class*=modal-valide-affaire] {

    ul {
        li {
            color: black;
        }
    }

    input.input-obs-just {
        height: 40px;
        width: 98%;
    }

    div.modal-action {
        position: relative;
        padding-top: 10px;

        &::after {
            clear: both;
            display: table;
        }

        button {
            width: 110px;
        }

        button:first-child {
            float: left;
        }

        button:not(:first-child) {
            float: right;
        }
    }
}

.tranche-spacer {
    margin-bottom: 28px;
}
//erreur dans le remplissage des conventions
#conventionPassage {
    @include table-bs-error;

    tr.dark-border-top:not(:first-child) {
        td, th {
            border-top-color: #888;
        }
    }

    tr.dark-border-top {
        td, th {
            background: #e6e6e6;
        }

        td, th:last-child {
            border-radius: 0 0 0 3px;
        }

        td, th:first-child {
            border-radius: 3px 0 0 0;
        }
    }

    tr.warning {
        td {
            background-color: #fdf5d9;
            border-color: #fceec1;
        }
    }
}
//
// Style bootstrap oldschool pour la validation
//
form div.clearfix.error {
    background: #fae5e3;
    padding: 10px 0;
    margin: -10px 0 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

form div.clearfix.error > label, form div.clearfix.error span.help-inline, form div.clearfix.error span.help-block {
    color: #9d261d;
}

form div.clearfix.error input, form div.clearfix.error textarea {
    border-color: #c87872;
    -webkit-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
    -moz-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
    box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
}

form div.clearfix.error input:focus, form div.clearfix.error textarea:focus {
    border-color: #b9554d;
    -webkit-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
    -moz-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
    box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
}

form div.clearfix.error .input-prepend span.add-on, form div.clearfix.error .input-append span.add-on {
    background: #f4c8c5;
    border-color: #c87872;
    color: #b9554d;
}

ul.tabs li a.error {
    background: #fae5e3;
    color: #9d261d;
    border: 1px solid #b9554d;
    border-bottom-color: transparent;
}

.fade-out {
    //position: relative;

    table, p {
        opacity: 0.4;
    }

    * {
        cursor: pointer;
    }
    /*&:hover {

        > .fade-out-message {
            opacity: 1.0;
        }
    }*/
    .fade-out-message {
        position: absolute;
        //opacity: 0.0;
        top: 46%;
        //transition: opacity 0.4s ease;
        width: 100%;
        text-align: center;

        .label {
            font-size: 1.2em;
        }
    }
}

table.pilotage {
    /**/
    .legende td, th {
        text-align: left;
    }

    td, th {
        background-color: white;

        a {
            color: #000;
        }

        text-align: center;

        &.row1, &.row1 a {
            color: $red;
        }

        &.row2, &.row2 a {
            color: $yellow;
        }

        &.row3, &.row3 a {
            color: $green;
        }

        &.row4, &.row4 a {
            color: $blue;
        }

        &.vmiddle {
            vertical-align: middle;
        }

        &.hleft {
            text-align: left;
        }

        &.global {
            font-weight: bold;
            font-size: 1.1em;

            .totaux {
                color: #000;
                font-size: 12px;
                font-weight: normal;

                a {
                    color: #000;
                }
            }
        }

        &.bleft {
            border-left: 1px solid #DDDDDD;
        }
    }

    th {
        background-color: #ffffff;
        text-align: center;

        img[data-type=logo] {
            display: block;
            max-width: 65px;
            max-height: 32px;
        }
    }

    td.entreprise {
        text-align: left;
        vertical-align: middle;

        > div {
            display: table;
            width: 100%;
        }

        span {
            display: table-cell;
            vertical-align: middle;

            img {
                max-width: 65px;
                max-height: 32px;
            }
        }

        span:first-child {
            width: 70px;
        }
    }

    tr.border-reveal {
        td {
            border-top-color: #CCCCCC;
        }
    }
}

.affaireToutes {
    font-size: 11px;
    float: right;
    margin-right: 5px;
}

th.bsFix, td.bsFix {
    // Car boostrap se plante parfois avec les CSS adjacents "+"
    border-left: 1px solid #DDDDDD;
}

.block-message-info {
    border-width: 1px;
    border-style: solid;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    padding: 14px;
    margin-top: 15px;
    color: $default-text-color;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #ddf4fb;
    border-color: #c6edf9;
}
//
// Gantt
//
.dataPanel {
    .ganttAff {
        cursor: pointer;

        &.moaerdf {
            background-color: darken($etapeERDFColorBase, 5%);
        }

        &.moaer {
            background-color: darken($etapeERColorBase, 5%);
        }

        &.moaerdf_branchement {
            background-color: darken($etapeBrchtColorBase, 5%);
        }

        &.moaerdf_postesource {
            background-color: darken($etapePosteSourceColorBase, 5%);
        }

        &.moaCartographie {
            background-color: darken($etapeCartographieColorBase, 5%);
        }

        .labelGantt {
            a {
                color: $default-text-color;
                display: block;
                height: 18px;

                &:active, &:visited {
                    color: $default-text-color;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.pagination {
    margin-top: 10px;

    .current, .disabled {
        display: none;
    }
}

.r270 {
    @include rotate(270deg);
}

#histoLoading {
    background: url('../../Content/assets/ajax-loader.gif') no-repeat transparent;
    padding-left: 40px;
    padding-top: 6px;
    height: 30px;
    margin-top: 10px;
}

.ajaxWait {
    background: url('../../Content/assets/ajax-loader.gif') no-repeat transparent;
    height: 32px;
    width: 32px;
}

#filtreCALoading {
    margin-top: 3px;
    display: none;
}

.waitOnAjaxUpload {
    &.wait {
        cursor: wait;
    }
}

.center-loading {
    cursor: wait;
    margin: 0 48%;
    padding-bottom: 25px;
}

.ui-multiselect-checkboxes {
    li {
        label {
            text-align: left;

            span {
                padding-left: 5px;
            }
        }
    }
}

.ui-multiselect-filter {
    input {
        width: auto;
        margin-left: 6px;
    }
}

.ui-multiselect-all {
    display: none;
}

.ui-multiselect-close {
    display: none;
}

.accordion {
    div {
        &.ui-accordion-content {
            padding: 6px;
        }

        table {
            margin-bottom: 0;
        }
    }
}

small.tiny {
    font-size: 10px;
}
//*******************************************/
//* Mon tableau de bord / filtre / pilotage */
//*******************************************/
.tdb-left {
    width: 49%;
    margin-right: 1%;
    display: inline-block;
    vertical-align: top;
}

.tdb-right {
    width: 49%;
    display: inline-block;
    vertical-align: top;
}

.filtreArea {
    @include borderRadius(5px);
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #F5F5F5;
    padding: 2px;
    width: unset;

    .filter-table {
        //display:none;
        tr td {
            border-top: none;
            vertical-align: middle;

            select {
                width: 280px;
            }
        }
    }
}

.recap-tableauBord {
    position: relative;
    h3 {
        font-size: 22px;
        margin-bottom: 10px;
        line-height: 1.2em;
    }

    p, .label {
        font-size: 1.4em;
        margin-bottom: 10px;
        line-height: 1.2em;
    }

    hr {
        margin: 10px 0 9px;
    }

    .red {
        color: $red;
        //font-weight: bold;
    }
}

.clear-file {
    position: relative;
    top: 4px;
}

.impersonate-block {
    margin-top: 4px;
    @include borderRadius(4px);
    border: 1px solid $gris;
    padding: 2px 4px 4px 4px;
    background-color: lighten($gris, 10%);
}

.disabled {
    color: #999;
}
//*******************************************/
//* aide */
//*******************************************/
.aide {
    .well {
        padding: 5px;
    }
}

.ariane {
    vertical-align: middle;
    display: inline-block;
}

.ariane-home {
    vertical-align: bottom;
    //   margin-right: 10px;
    //   margin-bottom: 10px;
}

.doc-keywords {
    font-weight: bold;
    color: slategray;
}

.gray-link {
    color: slategray;
    text-decoration: underline;
    font-weight: bold;
}

.download-icon {
    vertical-align: top;
    margin-left: 4px;
}

.open-icon {
    vertical-align: top;
    margin-left: 10px;
}

.reorder-icon {
    vertical-align: top;
    height: 16px;
}

.sort-icon {
    vertical-align: bottom;
    float: left;
    height: 16px;
}

.aide-table {
    tr {
        height: auto;

        th:nth-child(1) {
            border-right: none;
            width: 25px;
        }

        th:nth-child(2) {
            border-left: none;
        }

        td {
            vertical-align: middle;
        }

        td:nth-child(1) {
            border-right: none;
        }

        td:nth-child(2) {
            border-left: none;
        }
    }
}

.aide-create-btn {
    vertical-align: middle;
    margin-left: 20px;
}

.aide-edit-btn {
    vertical-align: middle;
}

.aide-rep-arbre {
    margin-bottom: 20px;

    .aide-rep-child {
        margin-bottom: 2px !important;

        &.visible-folder {
            display: block;
        }

        &.hidden-folder {
            display: none;
        }

        a {
            cursor: pointer;
        }

        a:hover {
            text-decoration: none;
        }

        .rep-is-selected {
            padding: 1px 5px 1px 5px !important;
            background-color: lightgray;
        }
    }
}

.help-panel {
    border: 1px solid #ddd;
    border-radius: 4px;

    .help-panel-heading {
        margin: 10px;
    }

    .help-panel-body {
        margin: 10px;
    }
}

.document-name {
    margin-bottom: 5px;
}

.btn-clipboard-link {
    margin-bottom: 5px;
    display: none;
}

.clipboard-link-open {
    margin-bottom: 5px;
    display: none;
}

.clipboard-link-dld {
    margin-bottom: 5px;
    display: none;
}

.clipboard-copy {
    margin-left: 20px;
}

.clipboard-iu {
    margin-left: 150px;
}

.clipboard-iu-msg {
    margin-left: 165px;
}

.copy-dld-msg, .copy-open-msg {
    display: none;
}

.help-sign {
    font-size: 1.9em;
    font-weight: bold;
}

.preview-class {
    margin-bottom: 5px;
}

.file-links-aide {
    padding: 0 0 5px 0 !important;
}

.file-links-aide-open {
    color: $bleuUrl;
}

.folder-state-switch-name {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.file-aide-label {
    padding: 0;
}
//*******************************************/
//* liste d'affaires */
//*******************************************/
table.table-affaires {
    margin-top: 5px;

    tr.red {
        color: $red;
        font-weight: bold;
    }

    tr.important td {
        background-color: #f4c8c5 !important;
    }

    tr.ok td {
        background-color: $tableColorOk !important;
    }

    tr.warning td {
        background-color: $tableColorWarning !important;
    }

    tr.error td {
        background-color: $tableColorError !important;
    }
}

div.dataTables_length label {
    float: left;
    text-align: left;
}

div.dataTables_length select {
    width: 75px;
}

div.dataTables_filter label {
    float: right;
    width: 270px;
    white-space: nowrap;

    input {
        width: 180px;
    }
}

div.dataTables_info {
    padding-top: 8px;
}

div.dataTables_paginate {
    float: right;
    margin: 0;
}

table.table {
    clear: both;
    margin-bottom: 6px !important;
}

span.highlight {
    background-color: yellow;

    &.hl-0 {
        background-color: yellow;
    }

    &.hl-1 {
        background-color: lawngreen;
    }

    &.hl-2 {
        background-color: cyan;
    }

    &.hl-3 {
        background-color: magenta;
    }

    &.hl-4 {
        background-color: red;
    }

    &.hl-5 {
        background-color: violet;
    }
}

table {
    &.table thead {
        .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
            cursor: pointer;
            //*cursor: hand;
        }

        .sorting {
            //background: url('images/sort_both.png') no-repeat center right;
        }

        .sorting_asc {
            //background: url('images/sort_asc.png') no-repeat center right;
        }

        .sorting_desc {
            //background: url('images/sort_desc.png') no-repeat center right;
        }

        .sorting_asc_disabled {
            //background: url('images/sort_asc_disabled.png') no-repeat center right;
        }

        .sorting_desc_disabled {
            //background: url('images/sort_desc_disabled.png') no-repeat center right;
        }
    }

    &.dataTable th:active {
        outline: none;
    }

    &.nostyle {
        width: auto;

        tr td {
            border-top: 0;
        }

        label {
            width: auto;
        }

        .clearfix {
            margin-bottom: 0;
        }
    }
}

.synthese-echanges {
    tr.dirty td {
        background-color: $tableColorError;
    }
}

.DTTT_print_info {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 150px;
    margin-left: -200px;
    margin-top: -75px;
    text-align: center;
    background-color: #3f3f3f;
    color: white;
    padding: 10px 30px;
    opacity: 0.9;
    @include borderRadius(5px);
    -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    -ms-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    -o-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);

    h6 {
        font-weight: normal;
        font-size: 28px;
        line-height: 28px;
        margin: 1em;
    }

    p {
        font-size: 14px;
        line-height: 20px;
    }
}

div.ColVis_collectionBackground {
    background-color: black;
    z-index: 1100;
}

div.ColVis_collection {
    position: relative;
    width: 150px;
    background-color: #f3f3f3;
    padding: 3px;
    border: 1px solid #ccc;
    z-index: 1102;
}

div.ColVis_collection button.ColVis_Button {
    background-color: white;
    width: 100%;
    float: none;
    margin-bottom: 2px;
}

div.ColVis_catcher {
    position: absolute;
    z-index: 1101;
}

button.ColVis_Button {
    text-align: left;
}

div.ColVis_collection button.ColVis_Button:hover {
    border: 1px solid #999;
    background-color: #f0f0f0;
}

span.ColVis_radio {
    display: inline-block;
    width: 20px;
}

.exbool {
    color: blue;
}

.exstring {
    color: brown;
}

.exdanger {
    color: orange;
}
//*******************************************/
//* admin matrice */
//*******************************************/
.matriceProfil {
    table-layout: fixed;
    width: 1000px;

    tr:first-child th {
        height: 260px;
        vertical-align: bottom;

        div {
            -moz-transform: rotate(-90deg);
            -moz-transform-origin: top left;
            -webkit-transform: rotate(-90deg);
            -webkit-transform-origin: top left;
            -o-transform: rotate(-90deg);
            -o-transform-origin: top left;
            top: 20px;
            width: 260px;
        }
    }

    a.matrice, div.matrice-poid {
        display: inline-block;
        width: 20px;
        height: 24px;
        text-align: center;
    }

    a.matrice-save, a.matrice-cancel {
        @extend .matrice;
        display: none;
    }
}
//*******************************************/
//* admin codemirror */
//*******************************************/
div.CodeMirror {
    @include borderRadius(4px);
    @include boxShadow(inset 0 1px 1px rgba(0, 0, 0, .075));
    background-color: white;
    border: 1px solid #cccccc;
    // Focus state
    &:focus, &.focus {
        @include boxShadow(#{inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6)});
        border-color: rgba(82, 168, 236, .8);
        outline: 0;
        outline: thin dotted \9; /* IE6-9 */
    }

    .cm-mustache {
        color: #00a1d1;
    }
}



.table-revue {
    tbody tr td {
        vertical-align: middle;
    }

    .btn.trigger-edit {
        padding-bottom: 1px;
    }
}

.territoireRevue {
    tr td {
        vertical-align: middle;
    }
}

.revue-form {
    /**/
    margin-bottom: 2px;

    textarea {
        width: 100%;
        margin-top: 2px;
        margin-right: 0;
        @include box-sizing(border-box);
    }

    .clearfix {
        margin-bottom: 0;
    }
}

.revue-actions {
    padding-top: 4px;
    text-align: right;
}

.tendance {
    @include borderRadius(3px);
    margin-bottom: 2px;
    // C'est stupide, mais on est obligé de preciser les padding dans les s/classes, à cause de la priorité des regles BS
    &.tendance-verte {
        padding: 5px;
        padding-left: 2px;
        background-color: lighten($green, 20%);
    }

    &.tendance-orange {
        padding: 5px;
        padding-left: 2px;
        background-color: lighten($orange, 20%);
    }

    &.tendance-rouge {
        padding: 5px;
        padding-left: 2px;
        background-color: lighten($red, 20%);
    }
}
//partie tableaux SVG
#Display-Carte {
    margin: -15px;
}

#table-carte {
    margin: auto;

    tr {
        &.no-border {
            border: none;

            & > td, & > th {
                border: none;
            }
        }
    }

    td, th {
        margin: 0;
        padding: 0;
        vertical-align: middle;
    }

    td {
        padding: 0;

        &.carte {
            //width: 400px;
        }

        &.entreprise:not(:empty) {
            height: 250px;
            display: block;
            overflow-y: auto;
            padding-top: 10px;
        }

        $de: 0;

        @for $i from 0 through 5 {
            $val: $i * -25;
            $de: $de+($i*4);

            &.c-#{$i} {
                background-color: saturate(adjust-hue(#02de20, $val), $de);
                //color: adjust-hue(#00B050, $val);
            }
        }
    }

    .table-details-prestataires {
        font-size: 12px;

        td, th {
            padding: 2px;
            border-top: 1px solid #ddd;
            color: #000;
            text-align: center;

            & + td {
                border-left: 1px solid #ddd;
            }

            &.libelle {
                text-align: left;
                font-weight: bold;
                padding-left: 10px;
            }

            &.green {
                background-color: lighten($green, 20%);
            }

            &.orange {
                background-color: lighten($orange, 20%);
            }

            &.red {
                background-color: lighten($red, 20%);
            }

            &.blue {
                background-color: lighten($blue, 20%);
            }
        }
    }
}

.table-colored {
    tr.green {
        td {
            background-color: #ddffee;
            color: black;
        }
    }

    tr.orange {
        td {
            background-color: #fff8dd;
            color: black;
        }
    }

    tr.red {
        td {
            background-color: #ffe9dd;
            color: black;
        }
    }

    tr.blue {
        td {
            background-color: #dde8ff;
            color: black;
        }
    }
}

.big-modal {
    width: 680px;
    margin: -250px 0 0 -400px;
}

.qualite-table {
    tr th {
        border-top: none;
    }
}

#svg-departements {
    vertical-align: middle;
    text-align: center;
    //height: 600px;
    //width: 600px;
    margin: auto;
}
//
// Procédure Article 2
//
.consultation-end {
    float: right;
    font-weight: normal;

    img {
        position: relative;
        top: 2px;
    }
}

.etape-container li ul li .consultation-end {
    float: none;
}

.procedure-article2,
.procedure-article2-externe {
    form {
        margin-bottom: 0;

        .clearfix.error {
            margin: 16px !important;
            padding: 0 !important;
        }
    }

    span.required {
        color: $red;
    }

    .form-observation {
        display: none; // Initial
    }
}

.procedure-article2 {
    .observations {
        margin-left: 10px;
    }
}

.procedure-article2-recap {
    span.required {
        color: $red;
    }

    .reponse {
        margin-left: 10px;
    }

    .form-stacked {
        padding: 0;
    }

    .form-repondre {
        display: none; // Initial
        form {
            margin-bottom: 10px;
        }

        .if-solde {
            display: none; // Initial
        }

        .clearfix.error {
            margin: 16px;
            padding: 0;
        }
    }

    table {
        margin-bottom: 0;

        tr.odd td {
            background-color: #E8ECF2;
        }

        tr.attention td {
            background-color: $tableColorError !important;
        }

        tbody:first-child tr:first-child td, tr.notop td {
            border-top: none !important;
        }

        td {
            hr {
                margin: 0;
            }
        }
    }
}

.exergue {
    padding: 10px;
    @include borderRadius(4px);
    background-color: #dadada;
}
//
// Tranche nommées
//
.tranche-name-container {
    height: 18px;
}

.tranche-name {
    margin-left: 2px;
    margin-right: 2px;

    img {
        height: 13px;
    }
}

.tranche-name-editor {
    input[type=text] {
        margin-left: 5px;
        padding-top: 5px;
        padding-bottom: 0;
        font-size: 12px;
        //vertical-align: auto; commenté car auto n'existe pas en valeur possible
    }

    .btn {
        padding: 3px;
    }
}

.select2-highlighted {
    .hors-plaque {
        background-color: $red;
        color: white;
    }
}

table.etape-form-halfsize {
    td {
        border: none;
        padding-left: 0;
    }
}

.hors-plaque {
    background-color: $tableColorError;
}

.tiny-legende {
    .legend-title {
        text-align: left;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 90%;
    }

    .legend-scale ul {
        margin: 0;
        margin-bottom: 5px;
        padding: 0;
        float: left;
        list-style: none;

        li {
            font-size: 80%;
            list-style: none;
            margin-left: 0;
            line-height: 18px;
            margin-bottom: 2px;
        }
    }

    ul.legend-labels li span {
        display: block;
        float: left;
        height: 16px;
        width: 30px;
        margin-right: 5px;
        margin-left: 0;
        border: 1px solid #999;

        &.danger {
            background-color: $tableColorError;
        }
    }
}

.contact-edit-groupe {
    &:not(.active) {
        ul {
            display: none;
        }
    }

    &.active {
        .tick-edit-groupe {
            display: none;
        }
    }
}

.fallback-config {
    list-style: none;

    li {
        border: 1px solid #808080;
        padding: .5em;
        border-radius: .5em;
        margin-bottom: .6em;
        cursor: all-scroll;
        user-select: none;

        &[data-movable="False"] {
            cursor: not-allowed;
        }

        background-color: #EEE;

        &:first-child {
            .move-up {
                display: none;
            }
        }

        &:nth-last-child(2) { // L'avant dernier
            .move-down {
                display: none;
            }
        }

        .movers {
            position: relative;
            float: right;
            margin-right: 10px;

            .move-up, .move-down {
                cursor: pointer;
                position: absolute;
                font-weight: bold;

                &:hover {
                    text-decoration: none;
                }
            }

            .move-up {
                top: -5px;
            }

            .move-down {
                top: 5px;
            }
        }
    }
}

.fallback-reset-defaults {
    font-size: .7em;
    color: $gris;

    &:hover {
        color: darken($gris, 10%);
    }
}

.syndicat-config {
    list-style: none;

    .toggle-syndicat {
        color: $default-text-color;

        &:hover {
            text-decoration: none;
            font-weight: bold;
        }
    }

    ul {
        display: none;
    }
}
//*******************************/
//*  Progress bar
//*******************************/
.progress-chantier {
    position: relative;

    .intro {
        position: relative;
        top: 5px;
        left: 0;
        right: 0;
        text-align: right;
        float: right;

        .percent {
            font-size: 2em;
            font-weight: bold;
        }
    }

    .info {
        float: left;
        position: absolute;
        top: 0;
        left: 100px;
    }

    .progress {
        overflow: hidden;
        height: 30px;
        position: relative;

        .progress-container {
            float: left;
            position: absolute;
            width: 100%;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            margin-top: 5px;
            margin-bottom: 5px;
            border-radius: 4px;
            box-shadow: 0px 0px 5px 01px rgba(0, 0, 0, 0.1);
        }

        .progress-bar {
            float: left;
            position: absolute;
            width: 0%;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
            margin-top: 5px;
            margin-bottom: 5px;
            color: #fff;
            text-align: center;
            border-radius: 4px;
            box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
            transition: width 0.6s ease;
        }

        .progress-limit {
            float: left;
            position: absolute;
            width: 6px;
            height: 100%;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            border-radius: 4px;
            box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
        }

        .progress-info {
            position: relative;
            color: white;
            font-weight: bold;
            height: 20px;
            line-height: 20px;
            padding: 5px;
            width: 40px;
            text-align: center;
            border-radius: 4px;

            &:after {
                right: 100%;
                top: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-right-color: inherit;
                border-width: 12px;
                margin-top: -12px;
            }
        }
    }
}
//*******************************/
//*  circliful
//*******************************/
.circliful {
    position: relative;

    .fa {
        margin: -10px 3px 0 3px;
        position: relative;
        bottom: 4px;
    }
}

.circle-text, .circle-info, .circle-text-half, .circle-info-half {
    width: 100%;
    position: absolute;
    text-align: center;
    display: inline-block;
}

.circle-info, .circle-info-half {
    color: #999;
}
//******************/
//* Mesure qualite */
//******************/
span {
    @include positiveNeg;
}


.qualite-reponse {
    // &.clearfix {
    //     // margin-left: 10px;
    //     // margin-bottom: 2px;
    //     //max-width: 650px;
    //
    //     &.error {
    //         margin-left: 10px;
    //         margin-bottom: 2px;
    //     }
    // }
    .required {
        color: $red;
    }

    .help-toggle {
        cursor: pointer;
    }

    pre {
        margin-bottom: .5em;
    }

    .help {
        font-style: italic;

        a {
            font-style: italic;
            text-decoration: underline;
        }
    }

    table {
        margin: 0;

        td {
            vertical-align: middle;
        }

        tr {
            &.probleme {
                td {
                    background-color: $tableColorError !important;
                }

                &.bloquant td {
                    background-color: darken(saturate($tableColorError, 30%), 5%) !important;
                }
            }

            &.meta-box td {
                border-top: none;
            }
        }

        td, th {
            &.radio {
                text-align: center;
            }
        }
    }
}

#cc-delais-error {
    background: #fae5e3;
    color: #b94a48;
    padding: 6px;
    border: 1px solid transparent;
    border-radius: 4px;
}
//*******************/
// Support utilisateur
//*******************/
.ui-widget-content.support {
    overflow-y: scroll;
    height: 450px;
}

.drag-source {
    height: 200px;
    overflow-x: hidden;
}

.support-target, .support-source {
    min-height: 300px;
    margin: 5px 5px 5px 5px;

    li {
        display: inline-block;
        cursor: move;
        color: #222;
        margin: 3px 3px 3px 0;
        padding: 5px;
        width: 410px;
        height: auto;
        font-size: 12px;
        text-align: center;
        border: 1px solid #aaa;
        border-radius: 5px;
        background-color: #FDFCE8;
    }
}

#support-generique-datas {
    .support-error {
        color: $red;
    }
}

.support-admin {
    background-color: lighten($green, 20%) !important;
}

.support-superadmin {
    background-color: lighten($blue, 20%) !important;
}

.support-generique {
    background-color: lighten($yellow, 20%) !important;
}

.support-delete {
    background: url(../../Content/assets/delete.png);
    width: 15px;
    height: 15px;
}

.ui-icon-red {
    width: 16px;
    height: 16px;
    background-image: url(../../Content/themes/bootstrapui/images/ui-icons_cd0a0a_256x240.png);
}

.contact-presentation {
    table {
        td {
            border-top: none;
            vertical-align: middle;
        }

        margin-bottom: 0;
    }

    form {
        margin: 0;
    }

    fieldset {
        padding-top: 0;
        margin: 0;
    }
}

.modal-contact {
    table tr th, td {
        vertical-align: middle;

        input[type=text] {
            width: 100% !important;
        }
    }

    .form-error {
        color: $red;
    }
}
//***********************/
// Exposition Intervenant
//***********************/
.show-cc-cp-container {

    label {
        padding-top: 0;
    }

    ul {
        display: inline-block;
        list-style-type: none;
    }

    input[type=checkbox],
    input[type=radio],
    span {
        vertical-align: top;
    }

    span {
        display: inline-block;
        padding-top: 3px;
    }

    + .show-cc-cp-container span {
        width: 215px;
    }
}
//****************************/
// Affaire, table des DT, DICT
//****************************/
.dtdict-editor {
    padding: 5px;
    margin-bottom: 1em;

    table {
        tr td {
            vertical-align: middle;
        }

        .clearfix {
            margin: 0;
            padding: 0;

            &.error {
                margin: 0;
                padding: 5px;
            }
        }
    }
}

.dtdict-table {
    border-top: none;
    border-left: none;

    tr td {
        vertical-align: middle;
        border-left: 1px solid #ddd;
    }

    .dtdict-type {
        font-weight: bold;
        font-size: 1.5em;
    }
}

.btnAjaxUpload + .uploading + .activate-non-concerne {
    margin-left: .5em;
}

.cancel-non-concerne .btn {
    margin-top: 1em;
}

.filigrane-non-concerne {
    color: #000;
    float: left;
    font-size: 2em;
    font-weight: bold;
    margin: 0 auto;
    opacity: .2;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    user-select: none;
    cursor: default;
}

.help-panel {
    border: 1px solid #ddd;
    border-radius: 4px;

    .help-panel-heading {
        margin: 10px;
    }

    .help-panel-body {
        margin: 10px;
    }
}

.champ-dependant {
    label {
        color: red;
        font-weight: normal;

        a {
            color: red;
        }
    }
}

.alerte-dtdict {
    color: red;
}
//********************************/
// Affaire - Blocs Dt-Dict/Amiante
//********************************/

div.etape-form[data-anchor=icolPhaseEtu],
div.etape-form[data-anchor=icolPhaseTrav] {
    label.radio {
        input[type=radio] {
            vertical-align: top;
        }
    }

    div.form-groupe {
        hr {
            margin: 0 0 10px 0;
        }

        table.trans-courrier,
        table.trans-mail {

            td, th {
                vertical-align: middle;
            }

            td {
                padding: 5px;
                border: none;

                input[type=radio], input[type=checkbox] {
                    vertical-align: middle;

                    + label {
                        display: inline-block;
                        vertical-align: middle;

                        + input[type=radio] {
                            margin-left: 5px;
                        }

                        + input[type=checkbox] {
                            margin-left: 5px;
                        }
                    }
                }

                input.hasDatepicker {
                    vertical-align: middle;
                    background: #fff;

                    + img {
                        display: inline-block;
                        vertical-align: middle;
                        top: 0;
                    }
                }

                span.btnAjaxUpload {
                    img {
                        vertical-align: bottom;
                    }
                }
            }
        }
    }
}

table.table-dce {
    margin: 18px 0 0;

    td {
        border: none;
    }

    ul {
        li {
            font-weight: bold;
            color: black;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            a {
                cursor: pointer;
            }
        }
    }
}

form.has-not-dtdict-with-datas {
    div.form-groupe {
        table {
            td {
                background-color: gray !important;
            }
        }

        div.legend {
            background-color: gray !important;
        }

        * {
            pointer-events: none;
        }
    }
}

table.trans-courrier,
table.trans-mail {
    td {
        span.cannotUploadFile {
            span {
                background: gray;
                pointer-events: none;
            }
        }
    }

    img.ui-datepicker-trigger {
        left: 0;
    }
}

td.Tableau.de.synthese,
td.Plan.de.synthese {
    &.drag-source {
        height: auto;
    }

    .drag-source {
        height: auto;
    }
}

//**************************/
// Informations Utilisateurs
//**************************/
.container {
    max-width: 750px;
    padding: 15px;
}
.container-footer{
    max-width: 800px;
    padding: 15px;
}

.file-iu-modal {
    margin-bottom: 5px;
}

.file-iu-delete-text {
    vertical-align: top;
}

.file-iu-delete-icon {
    vertical-align: top;
}

.file-url-gestion {
    font-size: 0.85em;
}

.contact-display-order {
    margin-left: 5px;

    li {
        border-radius: 2px;
        border: 1px solid $gris;
        padding: 3px;
        list-style: none;
        margin-bottom: 3px;
    }
}
//*********************************************/
// Choix de la plateforme des infos utilisateur
//*********************************************/
.plateforme-choice-line {
    margin-bottom: 35px;

    .plateforme-label {
        margin-left: 10px;
        width: 500px !important;
        text-align: left !important;
    }
}

.plateforme-error {
    padding: 0 0 10px 0 !important;

    .plateforme-label-error {
        width: 500px !important;
        text-align: left !important;
    }
}

.info-util-alert {
    background: #fae5e3;
    color: #b94a48;
    padding: 6px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.char_count {
    margin: 5px 0 5px 0;

    span {
        color: grey;
        font-style: italic;
    }
}

//********************************/
//*  Tableau de bord - logo
//********************************/
@import "gestion-logo.scss";

// Notes Perso
.has-alarme {
    opacity: .65;
    float: right;
    margin-top: 2px;
}

@import "select2";
@import "ftypes";

@import "font-awesome-4.5.0/scss/_variables.scss";

$fa-font-path: '../../Content/font-awesome-4.5.0/fonts';

@import "font-awesome-4.5.0/scss/font-awesome.scss";

//*******************************/
//*  Signature commande
//*******************************/
@import "_signature-commande";

//********************************/
//*  PPOSR
//********************************/
@import "_pposr";
//*******************************/
//*  DateTime Picker
//*******************************/
.datetimepicker {
    .glyphicon {
        display: inline-block;
        width: 14px;
        height: 14px;
        vertical-align: text-top;
        background-image: url(../../img/glyphicons-halflings.png);
        &.icon-arrow-right{
            background-position: -264px -96px;
        }
        &.icon-arrow-left{
            background-position: -240px -96px;
        }
    }
}
.prestation-urgente {
    color: red;
    font-size: 1.9em;
    font-weight: bold;
}

// Nécessaire pour avoir la classe close qui permet la fermeture en BS 2.3.1 (va comprendre) mais aussi un style correct
.modal-aide {
    .close-button {
        color: black;
        font-size: 13px;
        opacity: 1;
        font-weight: normal;
    }
}
#icp, #ppr {
    .question-mark {
        cursor: pointer;
        vertical-align: text-bottom;
    }

    .text-info {
        color: #31708f;
    }
}

div {
    &.sticky {
        &.blue {
            background: #2F96B4;
            color: white
        }
    }
}
.liste-affaires-liees {
    max-width: 1030px;
    margin-left: 20px;
}


// 20190107 - EPL-2611: surbrillance de la date du jour comme les calendrier en bs3
.ui-datepicker {
    td {
        .ui-state-default {
            &.ui-state-highlight {
                background-color: #eedc94;
            }
        }
    }
}

.espace-collaboratif {
    img {
        vertical-align: middle;
        height: 30px;
    }

    a {
        font-size: 15px;
    }
}

.help-title {
    margin-top: 10px;
}
