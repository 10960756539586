// Couleurs
$grisPresta: #efefef;
$grisHeader: #dadada;
$grisJalons: #d5d5d5;
$gristresfoncePresta: #969696;

$darkRedPresta: #9d261d;
$greenPresta: #D5FFC6;
$bleuClairPresta: #CEF0FF;
$orangePresta: #F7E879;

$red: #FFC6C6;
$orange: #f89406;
$redTextBadValue: #b9554d;
$redBackgroundBadValue: #f4c8c5;
$redBorderBadValue: #c87872;

$bleuUrl: #0069d6;

// Couleurs Enedis
$enedis-color-secondaire-turquoise: #00B2A9;
$enedis-color-bleu-clair: #00A3E0;
$enedis-color-primaire-bleu-primaire: #005EB8;

// Couleurs commande prestation
$commPrestaDarkBlue: #5d9bd5;
$commPrestaLightBlue: #9bc2e6;
$commPrestaTextHead: #2e74b5;
$commPrestaTextTitle: #1f4d78;

// pilotages
$redPilo: $darkRedPresta;
$shinyRed: #e60000;
$orangePilo: #f89406;

// planning
$mediumGrey: #cccccc;

.form-groupe {
}

a.sous-etape {
    display: block;
}

.attente-pposr {
    background-color: $grisHeader;
}

.prestation-osr-content {
    padding-bottom: 1px;

    .form-prestation {
        text-align: left;
        padding-left: 20px;
    }

    div.bandeau-prestation {
        cursor: pointer;
        border-radius: 2px;
        vertical-align: middle;
        -webkit-touch-callout: none; // iOS Safari
        -webkit-user-select: none; // Chrome/Safari/Opera
        -moz-user-select: none; // Firefox
        -ms-user-select: none; // Internet Explorer/Edge
        user-select: none; // Non-prefixed version, currently not supported by any browser
        &.aaccepter {
            background-color: $orangePresta;
        }

        &.refusee {
            background-color: $red;
        }

        &.abandonnee {
            background-color: $red;
        }

        &.enattente {
            background-color: $grisPresta;
        }

        &.terminee {
            background-color: $greenPresta;
        }

        &.autres {
            background-color: $bleuClairPresta;
        }

        .bandeau-data {
            vertical-align: middle;
            padding: 5px 0 5px 5px;
            text-align: left;
            width: 30%;
            display: inline-block;

            .bandeau-attr-name {
                padding: 5px 0 5px 0;

                span {
                    font-weight: bold;
                }
            }
        }

        .bandeau-arrow {
            display: inline-block;
            vertical-align: middle;

            .arrow-img {
                width: 22px;
            }
        }
    }

    .prestation-form-title {
        background-color: $grisHeader;
        font-weight: bold;
        padding: 5px;
        margin: 10px 0 10px 0;
    }

    .prestation-data-block {
        display: none;
        border: none;

        .bloc-solution-technique {
            padding-bottom: 10px;

            &.borderbottom {
                border-bottom: 1px solid #d5d5d5;
            }
        }

        .attachement-container {
            padding: 0 5px;
        }

        .row {
            margin: 10px 0 0 0;
            vertical-align: middle;

            &.comment-block {
                &.bordertop {
                    border-top: 1px solid #d5d5d5;
                }

                .comment {
                    word-wrap: break-word;
                    margin: 10px 20px 0px 20px;

                    textarea {
                        font-size: 14px;
                        width: 600px;
                    }

                    pre {
                        font-size: 14px;
                        font-family: 'Arial', 'Helvetica', sans-serif;
                        width: 600px;
                    }
                }

                .comment-label {
                    margin: 10px 0 0 20px;
                }
            }

            &.zero-margin {
                margin: 0;
            }

            &.zero-bottom-margin {
                margin-bottom: 0;
            }

            &.row-btn-action-prestation {
                margin-top: 20px;
                border-bottom: 1px solid $grisJalons;

                .noselect {
                    -webkit-touch-callout: none; // iOS Safari
                    -webkit-user-select: none; // Chrome/Safari/Opera
                    -moz-user-select: none; // Firefox
                    -ms-user-select: none; // Internet Explorer/Edge
                    user-select: none; // Non-prefixed version, currently not supported by any browser
                }

                .btn {
                    margin-right: 15px;

                    &:not([data-action-type='contact'], [data-action-type='point-d-arret']) {
                        min-width: 125px;
                        text-align: center;
                    }
                }

                .refuser-prestation-osr {
                    width: 100px;
                    text-align: center;
                }

                &.modifier {
                    text-align: right;
                }

                .osr-link {
                    background-color: $bleuUrl;
                    border-radius: 3px;
                    padding: 2px;
                }
            }

            &.error {
                .value-prestation-data {
                    color: $redTextBadValue;

                    input, textarea {
                        color: $redTextBadValue;
                        background: $redBackgroundBadValue;
                        border-color: $redBorderBadValue;
                    }

                    span {
                        color: $redTextBadValue;

                        &.error-number {
                            margin-left: 50px;
                        }

                        &.error-date {
                            margin-left: 25px;
                        }
                    }

                    div {
                        margin-top: 10px;
                    }
                }

                div {
                    color: $redTextBadValue;
                }
            }

            .label-prestation-data {
                font-weight: bold;

                &.sous-label {
                    font-style: italic;
                    font-size: 12px;

                    span {
                        padding-left: 15px;
                    }
                }
            }

            .label-prestation-twocols {
                font-weight: bold;
                margin-left: 5px;
            }

            .value-prestation-data {
                &.editable-planif-date input {
                    width: 85px;
                }

                .ui-datepicker-trigger {
                    vertical-align: middle;
                }
            }

            div.error, span.error {
                color: $redTextBadValue;

                input, textarea {
                    color: $redTextBadValue;
                    background: $redBackgroundBadValue;
                    border-color: $redBorderBadValue;
                }
            }

            .greyed-italic {
                color: $gristresfoncePresta;
                font-style: italic;
                font-weight: normal;
            }

            .presta-nb-car-comment {
                margin-top: 10px;
            }

            .switchable-dropdown {
                width: 280px;

                &.large-dropdown {
                    width: 550px;
                }

                &.small-dropdown {
                    width: 85px;
                    font-size: 12px;
                }
            }

            input[type='radio'] {
                margin-right: 12px;
                vertical-align: top;
            }

            .radio-label {
                display: inline-block;
                padding-top: 3px;
                margin-right: 20px;
            }

            .value-prestation-checkboxes-data {
                margin: 0 0 5px 0;

                input[type='checkbox'] {
                    margin-right: 12px;
                    vertical-align: middle;
                }

                span {
                    vertical-align: text-bottom;
                }
            }

            .value-prestation-radio-data {
                margin-left: 0;
            }

            .pub-priv-header {
                text-decoration: underline;
                margin-bottom: 5px;
            }

            .hasDatepicker {
                background-color: white;
                cursor: default;
            }

            .img-type-modification {
                box-sizing: border-box;
                padding: 20px 0 20px 0;
                width: 550px;
                height: auto;
            }

            .blue-label {
                color: blue;
            }

            .jalon-title {
                color: grey;
                font-size: 13px;
            }
        }
    }

    .prestation-data-block.planificationtravaux, .prestation-data-block.planificationetude {
        div[class='row'] {
            border-bottom: 1px solid $grisHeader;
            padding-bottom: 5px;
        }

        .osr-link {
            background-color: blue;
        }
    }

    .barre-avancement-prestation {
        margin: 30px 0 55px 0 !important;
        vertical-align: middle;
        text-align: center;

        .jalon-prestation {
            box-sizing: border-box;
            cursor: pointer;
            color: white;
            border-radius: 4px;
            width: 60px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            padding-top: 2px;

            &.grey-jalon {
                background-color: $grisJalons;
            }

            &.green-jalon {
                background-color: green;
            }

            &.empty-jalon {
                width: 10px;
                background-color: $gristresfoncePresta;
            }
        }

        .jalon-arrow {
            vertical-align: middle;
            background-color: white;
            display: inline-block;
            font-size: 2.9em;
            color: $gristresfoncePresta;
            padding-top: 3px;
        }
    }
}

.popover .inner.popover-jalon {
    box-sizing: border-box;
    padding: 2px;
    border: none;
    width: 200px;

    h5 {
        text-align: center;
        padding: 5px;
        border-bottom: none;
    }

    .content {
        padding: 10px;

        p {
            text-align: center;
            line-height: 12px;
        }
    }
}

#refus-prestation-modal {
    #champ-interlocuteur {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .dropdown-refus-prestation {
        width: 300px;
        margin-bottom: 60px;
    }

    #alert-motif-refus-prestation {
        display: none;
        font-weight: bold;
        color: red;
    }

    .motif-refus-prestation {
        display: none;
        margin: 20px 0 20px 0;
        width: 510px;
        height: 200px;
    }
}
//*************************** FILTRE (TdB et Liste prestations) ********************************/
.filtre-prestations-osr {
    select {
        width: 250px;
    }

    input {
        width: 250px !important;

        &.datepicker, &.weekpicker {
            width: 150px !important;
        }
    }

    .dates-prestations {
        margin: 5px 0 15px 0 !important;

        label.dates-title {
            margin: 0 0 5px 10px !important;
        }
    }

    .large-field {
        .select2-container {
            max-width: none !important;
        }

        select {
            width: 400px;
        }
    }

    .semaine-display {
        margin-top: 10px !important;
        font-style: italic;
        font-weight: bold;
    }

    .periodes-planning {
        margin: 0 0 15px 0 !important;
    }
}

.form-filter-prestations-liste {
    margin-bottom: 20px;
}
//**************************** Tableau de bord ***************************/
// Evite que le numéro de page active recouvre le calendrier............
.pagination .active a {
    z-index: 0;
}

.pilotage-prestation-osr {
    th {
        vertical-align: middle;
        text-align: center;
        color: darkblue;
        padding: 4px !important;
    }

    td {
        vertical-align: middle;
        text-align: center;
        padding: 4px !important;

        &.prestation_osr-urgente {
            color: $darkRedPresta;

            a {
                font-weight: bold;
                color: $darkRedPresta;
            }
        }
    }

    .total-osr-class {
        font-weight: bold;
        color: #666666;
    }
}
//*************************** Liste Affaires/Prestations *****************************/
div#alert-prestas-truncated {
    color: $redTextBadValue;
    background: $redBackgroundBadValue;
    border-color: $redBorderBadValue;
    padding: 10px;
    margin: -10px 0 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
}

.liste-affaire-branchement {
    tr {
        &.red-line-prestation {
            color: $shinyRed;

            a {
                color: $shinyRed;
            }
        }

        &.orange-line-prestation {
            color: $orangePilo;

            a {
                color: $orangePilo;
            }
        }

        a {
            color: black;

            &.recommander {
                color: white;
            }
        }

        td.declare-done {
            text-align: center;

            .check-declare-done {
                cursor: pointer;
            }
        }
    }
}

//******************************** Planning prestations ************************/
.planning-pposr-filtre {
    margin-bottom: 20px;
}
// pour surligner une semaine entière à la sélection
#ui-datepicker-div.week-calendar .ui-datepicker-calendar tr:hover {
    background-color: lightgray;
}

.planning-pposr-wrapper {
    overflow: auto;
    margin-top: 20px;
    text-align: center;

    .table-planning-pposr {
        border-color: darkgrey;

        &.one-day {
            max-width: 750px;
            display: inline-table;
        }

        td, th {
            border-color: darkgrey;
            border-bottom-width: 1px;
            text-align: center;
            vertical-align: middle;
            width: 46px;

            &.planning-sunday-cell {
                background-color: $mediumGrey;
            }

            &.planning-total-cell {
                background-color: lightblue;
            }

            .clickable-chkbx {
                cursor: pointer;
            }

            a {
                vertical-align: text-bottom;

                &.prestation-done {
                    color: grey;
                }
            }

            &.detailed-plan-cell {
                text-align: left;
            }
        }

        .planning-header-line {
            background-color: $grisHeader;

            &.planning-main-header {
                font-size: 1.2em;
            }

            &.planning-navigable-header a {
                color: $bleuUrl;
            }

            &.planning-day {
                color: grey;
            }

            .planning-empty-cell {
                background-color: white;
                border: none;
            }
        }
    }
}

.planning-pposr-navigate {
    img {
        width: 12px;
        padding-bottom: 2px;

        &.previous {
            margin-right: 10px;
        }

        &.next {
            margin-left: 10px;
        }
    }
}

.pposr-setdaterealise-modal-content {
    margin-top: 200px;

    .modal-body {
        text-align: center;
    }
}
// comprends pas pourquoi il ne se dimensionne pas correctement sur
//la vue planning et pas sur la vue affaire en mode modification....
.sticky {
    height: auto !important;
}

//*************************** Table attachement étude **************************/
.table-attachement {
    tr {
        height: 35px;
    }

    td, th {
        text-align: center;
        vertical-align: middle;

        &.statut-attachement span {
            cursor: default;
            text-decoration: underline;
        }

        &.attachement-link a {
            cursor: pointer;
            color: blue;
        }
    }

    th {
        color: blue;
    }
}

//***************************** Détail attachement étude ****************************/
#attachement-detail-buttons {
    .floating-btn {
        position: fixed;
        bottom: 50px;
        width: 140px;
        z-index: 2;

        &#saving-wait {
            width: 200px;
        }
    }
}

.view-table-attachement {
    .detail-attachement-title {
        margin-bottom: 25px;

        span {
            text-decoration: underline;
        }
    }

    .panel-detail-attachement {
        vertical-align: middle;

        .price-choice {
            padding-bottom: 20px;
        }

        select {
            width: 200px !important;
        }

        .group-name {
            text-align: center;
        }

        .prix-point-text {
            text-align: center;

            .label-prix-point {
                font-weight: bold;
            }
        }

        .label-serie-prix {
            text-align: center;
            font-weight: bold;
        }
    }

    .table-detail-attachement {
        .group-name {
            text-align: center;
        }

        .column-titles th {
            color: blue;
        }

        input {
            width: 100px;
        }

        tfoot {
            background-color: $grisPresta;
        }

        tr.value-used {
            background-color: $grisPresta;
        }

        td.btn {
            width: 150px;
        }

        td.error {
            color: $redTextBadValue;

            input {
                color: $redTextBadValue;
                background: $redBackgroundBadValue;
                border-color: $redBorderBadValue;
            }
        }

        .contradictoire {
            color: $redTextBadValue;
            font-size: 1.1em;
            font-weight: bolder;
        }
    }
}

//*********************************** Document Commmande Prestation ***********************************/
.head-logo-pdf-osr {
    text-align: center;

    img {
        width: 200px;
    }
}

.head-title-pdf-osr {
    text-align: center;
    margin: 55px 0 25px;
    font-size: 26px;
}

#doc-commande-prestation {
    font-family: 'Calibri', 'Arial', sans-serif;
    font-size: 18px;

    .type-doc {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        background-color: $enedis-color-secondaire-turquoise;
        padding: 2px 0 2px 0;
        margin-top: 20px !important;
        color: white;
        border: 1px solid black;
    }

    .main-info {
        margin: 5px 0 25px 0;

        .infos-title {
            color: darkblue;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .text-block {
        margin: 7px 0 !important;

        .text-head {
            text-align: center;
            color: darkblue;
            margin: 20px 0 5px;
            font-size: 21px;
            text-decoration: underline;
            font-weight: bold;
        }

        .text-title {
            color: darkblue;
            margin-top: 4px;
            text-decoration: underline;
            font-weight: bold;
        }

        .text-detail {
            margin: 20px 0;
        }
    }

    table {
        border-color: black;
        margin-bottom: 0;
        page-break-inside: avoid;

        &.no-border-top {
            border-top: none;

            &.td-no-top td {
                border-top: none;
            }
        }

        &.table-margin-top {
            margin-top: 25px;
        }

        &.table-risques, &.table-desc-trav, &.table-travaux-client, &.table-basse-tension {
            tr {
                &.lone-line td {
                    width: 78%;

                    &.attr-name {
                        width: 22%;
                    }
                }

                td {
                    width: 25%;
                }
            }
        }

        &.table-ordre-exec {
            td {
                width: 28%;

                &.attr-name {
                    width: 22%;
                }
            }
        }

        &.table-interlocuteur {
            td {
                width: 30%;

                &.attr-name {
                    width: 20%;
                }
            }
        }

        &.table-comm-risques {
            td {
                width: 85%;

                &.attr-name {
                    width: 15%;
                }
            }
        }

        &.table-travaux-demandes {
            td {
                width: 28%;

                &.attr-name {
                    width: 22%;
                }
            }

            &.first-block {
                margin-top: 35px;
            }

            .attr-name-only .attr-name {
                text-align: center;
            }

            .six-by-line-demande {
                td {
                    width: 23.33%;

                    &.attr-name {
                        width: 10%;
                    }
                }
            }

            .six-by-line-equal {
                td {
                    width: 11.33%;

                    &.attr-name {
                        width: 22%;
                    }
                }
            }
        }

        &.table-desc-trav tr.six-by-line td {
            width: 22%;

            &.attr-name {
                width: 16%;

                &.partie {
                    width: 12%;
                }
            }
        }

        tr {
            max-height: 2px;

            td {
                border-color: black;
                vertical-align: middle;

                &.attr-name {
                    background-color: $enedis-color-bleu-clair;
                    color: white;
                    font-weight: bold;
                }

                .comm-label {
                    // plus de style particulier (pour l'instant... je garde la classe)
                }

                .comm-email {
                    font-style: italic;
                }

                .comm-empty {
                    font-style: italic;
                    color: grey
                }
            }

            th {
                font-size: 21px;
                color: white;
                border-color: black;
                text-align: center;
                background-color: $enedis-color-secondaire-turquoise;
                border-bottom: none;
                vertical-align: middle;
            }
        }
    }

    .infos-travaux.margin-added {
        margin-bottom: 200px;
    }
}

//********************** Modale soumission prestation ************************************/
#submit-prestation-modal #liste-prerequis {
    margin-left: 35px;
}

//*********************** Points d'arrêt *************************************************/
.pa-objet-manuel {
    margin: 10px 0;
}

.alert-saisie-objet {
    display: none;
    font-weight: bold;
    color: $redPilo;
}

.type-objet-norme {
    width: 500px;
}

//*********************** Spécifique modale point d'arrêt pour PPOSR *********************/
.modal-pa-planning-branchement {
    //
    // Style bootstrap oldschool pour la validation
    //
    form div.clearfix.error {
        background: #fae5e3;
        padding: 10px 0;
        margin: -10px 0 10px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
    }

    form div.clearfix.error > label, form div.clearfix.error span.help-inline, form div.clearfix.error span.help-block {
        color: #9d261d;
    }

    form div.clearfix.error input, form div.clearfix.error textarea {
        border-color: #c87872;
        -webkit-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
        -moz-box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
        box-shadow: 0 0 3px rgba(171, 41, 32, 0.25);
    }

    form div.clearfix.error input:focus, form div.clearfix.error textarea:focus {
        border-color: #b9554d;
        -webkit-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
        -moz-box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
        box-shadow: 0 0 6px rgba(171, 41, 32, 0.5);
    }

    .modal-content {
        width: 720px;
    }

    .row {
        margin-bottom: 10px !important;
    }

    label {
        font-weight: bold;

        .foundDests {
            font-weight: normal;
        }
    }

    .echange-info-autre, .echange-info-objet, .echange-info-probleme, .echange-info-solution {
        textarea, .select2, select {
            width: inherit !important;
        }
    }

    .file-input {
        height: 25px;
    }

    input[type="file"] {
        display: inline-block;
    }
}

.modal-echange-info {
    .point-arret-modal-loading {
        display: none;

        img {
            width: 18px;
        }
    }
}

//******************* Fiche de comptage nouvelle version *********************************/
#form-collecte-pposr {
    .field-validation-error {
        color: #a94442 !important;
    }

    select, input[type="text"], textarea {
        width: 240px;

        &.index-value {
            // retrait de l'addon en prepend pour la largeur totale
            width: 211px
        }
    }

    select, input[type="checkbox"] {
        cursor: pointer;
    }

    .compteur-index {
        margin-bottom: 20px;

        .form-group {
            margin-bottom: 0;

            .index-title {
                font-weight: bold;
                font-size: 15px;
            }
        }
    }
}

#consult-collecte-pposr {
    margin-top: 20px;

    #headbar {
        margin: 25px 0;
    }

    #collecte-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 15px;
    }

    #button-pdf {
        text-align: right;
    }

    .panel {
        margin-bottom: 20px;
        border-top-width: 1px !important;
        padding-right: 0;
        padding-left: 0;

        .panel-heading {
            font-weight: bold !important;
            margin-bottom: 15px;
            font-size: 16px;
        }

        .label-fichecomptage-consult {
            font-weight: bold !important;
        }

        .row {
            line-height: 15px;

            &:not(.no-bottom-border) {
                border-bottom: 1px solid #337ab7;
                margin-bottom: 8px !important;
            }
        }
    }
}
