﻿a[data-ext], a.img-link {
    padding: 3px 0 5px 20px;
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        height: 18px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 3px;
        background-repeat: no-repeat;
        background-image: url('../../Content/assets/ftypes/sprite.png');
        background-position: -2px -210px;
    }
}

a[data-ext=".doc"], a[href*=".doc"],
a[data-ext=".docx"], a[href*=".docx"],
a[data-ext=".rtf"], a[href*=".rtf"] {
 &:after {
    background-position: -2px -158px;
    }
}

a[data-ext=".xls"], a[href*=".xls"],
a[data-ext=".xlsx"], a[href*=".xlsx"] {
    &:after {
    background-position: -2px -28px;
    }
}

a[data-ext=".dgn"], a[href*=".dgn"] {
    &:after {
    background-position: -2px -2px;
    }
}

a[data-ext=".zip"], a[href*=".zip"] {
    &:after {
    background-position: -2px -184px;
    }
}

a[data-ext=".jpg"], a[href*=".jpg"],
a[data-ext=".jpeg"], a[href*=".jpeg"],
a[data-ext=".png"], a[href*=".png"],
a[data-ext=".gif"], a[href*=".gif"],
a[data-ext=".bmp"], a[href*=".bmp"],
a[data-ext=".tif"], a[href*=".tif"] {
    &:after {
    background-position: -2px -54px;
    }
}

a[data-ext=".pdf"], a[href*=".pdf"] {
    &:after {
    background-position: -2px -80px;
   }
}

a[data-ext=".ppt"], a[href*=".ppt"],
a[data-ext=".pptx"], a[href*=".pptx"] {
    &:after {
    background-position: -2px -106px;
    }
}

.sprite {
    background-image: url('../../Content/assets/ftypes/sprite.png');
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
}

.document-text {
    background-position: -2px -132px;
}

.man-img {
    display: none;
}

.hover-text[data-ext=".jpg"]:hover ~ .man-img,
.hover-text[data-ext=".jpeg"]:hover ~ .man-img,
.hover-text[data-ext=".png"]:hover ~ .man-img,
.hover-text[data-ext=".gif"]:hover ~ .man-img,
.hover-text[data-ext=".bmp"]:hover ~ .man-img,
.hover-text[data-ext=".tif"]:hover ~ .man-img {
    display: block;
}
