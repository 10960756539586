﻿&.btEdit {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/edit.png');
}

&.btDelete {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/delete.png');
}

&.btDetails {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/details.png');
}

&.btApercu {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/apercu.png');
}

&.btCreate {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/create.png');
}

&.btIndex {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/index.png');
}

&.btPublish {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/publish.png');
}

&.btDownload {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/download.png');
}

&.btRefresh {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/refresh.png');
}

&.btRevert {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/revert.png');
}

&.btTrash {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/trash.png');
}

&.btApply {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/apply.png');
}

&.btBackup {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/Backup.png');
}

&.btRecycle {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/RecycleBin.png');
}

&.btArchive {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/Archive.png');
}

&.btZip {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/zip.png');
}

&.btCreateRep {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/add-rep.png');
}

&.btAddFile {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/add-file.png');
}

&.btDetails {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/view_detailed.png');
}

&.btThumb {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/view_thumbnail.png');
}

&.btLight {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/light-bulb.png');
}

&.btCSV {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/document-excel-csv.png');
}

&.btInsecte {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/bug.png');
}

&.btUser {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/user-worker.png');
}

&.btWork {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/drill.png');
}

&.btExchange {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/mail-send-receive.png');
}

&.btCopy {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/clipboard--arrow.png');
}

&.btPlus {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/plus.png');
}

&.btPrint {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/printer.png');
}

&.btMoins {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/minus.png');
}

&.btColumn {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/table-select-column.png');
}

&.btPersonneCol {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/user-worker-boss.png');
}

&.btListe {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/application-detail.png');
}

&.btConsultation {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/alarm-clock-select-remain.png');
}

&.btContact {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/question.png');
}

&.btPointArret {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/exclamation-button.png');
}

&.btAddExterne {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/building-plus.png');
}

&.btSkull {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/skull.png');
}

&.btPdf {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/document-pdf-text.png');
}
&.btDoc {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/ftypes/document-word.png');
}
&.btImg {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/ftypes/document-image.png');
}
&.btExcel {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/ftypes/document-excel.png');
}
&.btPpt {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/ftypes/document-powerpoint.png');
}
&.btText {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/ftypes/document-text.png');
}
&.btDefault {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/ftypes/document.png');
}



//&.btPdf { : Fugue est plus joli
//	@include degradeWithImage($blanc, $gris,'../../Content/assets/pdf.png');
//}
&.btSchedule {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/clock--plus.png');
}

&.btExport {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fugue/folder-share.png');
}

&.btOpen {
    @include degradeWithImage($blanc, $gris, '../../Content/assets/fm/folder_16.png');
}
